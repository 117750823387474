import "./App.css";
import React from "react";
import Login from "./components/Login/Login";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../src/helpers/constants";
import Dashboard from "./components/Dashboard/Dashboard";
import { Route, Routes, Navigate } from "react-router-dom";
import LocalStorage from "./components/Login/local-storage";

export default function App() {
  const ls = new LocalStorage();
  const [token, setToken] = React.useState(ls.getLocalStorageItem());
  const navigate = useNavigate();
  function changeLoginStatus(value) {
    if (value) {
      ls.removeLocalStorageItem("USER");
      ls.removeLocalStorageItem("ZOHO");
      setToken(null);
      navigate("/");
    }
  }
  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            token ? (
              <Navigate to={CONSTANTS.PAGE_DASHBOARD} />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_DASHBOARD}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_DASHBOARD}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_SENSOR_BOARDS_ADD}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_SENSOR_BOARDS_ADD}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_SENSOR_BOARDS_VIEW}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_SENSOR_BOARDS_VIEW}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_QUANTIFIER_ADD}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_QUANTIFIER_ADD}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_QUANTIFIER_VIEW}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_QUANTIFIER_VIEW}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_QUANTIFIER_REPORT}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_QUANTIFIER_REPORT}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_PACKET_LOSS}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_PACKET_LOSS}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_SENSOR_LOCATION}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_SENSOR_LOCATION}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />

        <Route
          path={CONSTANTS.PAGE_SENSOR_KPI}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_SENSOR_KPI}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />

        <Route
          path={CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_ADD}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_ADD}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_VIEW}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_VIEW}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_FIELD_NOTES_ADD}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_NOTES_ADD}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_FIELD_NOTES_VIEW}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_NOTES_VIEW}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_EQUIPMENT_VIEW}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_EQUIPMENT_VIEW}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_FIELD_REPORTS_ADD}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_REPORTS_ADD}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_FIELD_REPORTS_VIEW}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_REPORTS_VIEW}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_FIELD_SAFETY_NEW_SITE}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_SAFETY_NEW_SITE}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_FIELD_SAFETY_VIEW}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_SAFETY_VIEW}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
        <Route
          path={CONSTANTS.PAGE_FIELD_SAFETY_JOURNEY_MANAGEMENT}
          element={
            token ? (
              <Dashboard
                token={token}
                changeLoginStatus={changeLoginStatus}
                page={CONSTANTS.PAGE_FIELD_SAFETY_JOURNEY_MANAGEMENT}
              />
            ) : (
              <Login setToken={setToken} />
            )
          }
        />
      </Routes>
    </div>
  );
}
