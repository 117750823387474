
import axios from 'axios';
import moment from 'moment';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import CONSTANTS from '../../helpers/constants';
import InputLabel from '@mui/material/InputLabel';
import LocalStorage from "../Login/local-storage";
import React, { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FormControl from '@mui/material/FormControl';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AddQuantifier(props) {
    let ls = new LocalStorage()
    const navigate = useNavigate()
    const [tcm, setTCM] = useState('')
    const [af, setAf] = useState(false)
    const [site, setSite] = useState('')
    const [notes, setNotes] = useState('')
    const [sensor_1, setSensor_1] = useState()
    const [sensor_2, setSensor_2] = useState()
    const [sensor_3, setSensor_3] = useState()
    const [newForm, setNewForm] = useState("new")
    const [siteData, setSiteData] = useState([])
    const [loading, setLoading] = useState(false)
    const [sensorData, setSensorData] = useState([])
    const [quantifier, setQuantifier] = useState('')
    const [operational, setOperational] = useState('')
    let [quantifier_id, setQuantifierId] = useState('')
    const [power, setPower] = useState('instack')
    const [undergroundPacks, setUndergroundPacks] = useState({
        battery: 1,
        tcm: 1
    })
    const [testDate, setTestDate] = useState(moment().startOf('day'))
    const [fieldDate, setFieldDate] = useState(moment().add(2, 'days').startOf('day'));
    const [bundleDate, setBundleDate] = useState(moment().startOf('day'))

    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        let new_data = props.data ? props.data : {}
        if (name === 'site') {
            setSite(value)
            if (props.isEdit) {
                new_data.site = value
            }
        }
        else if (name === 'quantifier_name') {
            setQuantifier(value)
            if (props.isEdit) {
                new_data.quantifier_name = value
            }
        }
        else if (name === 'tcm') {
            setTCM(value)
            if (props.isEdit) {
                new_data.tcm = value
            }
        }
        else if (name === 'notes') {
            setNotes(value)
            if (props.isEdit) {
                new_data.notes = value
            }
        }
        else if (name === 'operational') {
            setOperational(value)
            new_data.operational = value
        }
        if (props.isEdit)
            props.setData(new_data)
    };

    const handleSubmit = (quant_id) => {
        let sensor = []
        let build_type = ''
        let power_infrastructure = {}
        if (quant_id && quantifier_id !== quant_id) {
            quantifier_id = quant_id
        }
        let number_of_sensors = 0
        if (sensor_1) {
            sensor.push({
                ...sensor_1, board: 1
            })
            number_of_sensors += 1
        }
        if (sensor_2) {
            sensor.push({
                ...sensor_2, board: 2
            })
            number_of_sensors += 1
        }
        if (sensor_3) {
            sensor.push({
                ...sensor_3, board: 3
            })
            number_of_sensors += 1
        }

        // Adding the stock of quantifiers in zoho
        if (newForm === 'new') {
            if (number_of_sensors === 3) {
                build_type = CONSTANTS.SOIL_SENSE_3_SENSORS_NEW
                compositeItem(CONSTANTS.ZOHO_SOIL_SENSE_3_NEW, 1, 'Soil-Sense')
            } else if (number_of_sensors === 2) {
                build_type = CONSTANTS.SOIL_SENSE_2_SENSORS_NEW
                compositeItem(CONSTANTS.ZOHO_SOIL_SENSE_2_NEW, 1, 'Soil-Sense')
            } else {
                build_type = CONSTANTS.SOIL_SENSE_DRY_WATER_NEW
                compositeItem(CONSTANTS.ZOHO_WELL_SENSOR_NEW, 1, 'Soil-Sense')
            }
        } else if (newForm === 'old') {
            if (number_of_sensors === 3) {
                if (af) {
                    build_type = CONSTANTS.SOIL_SENSE_3_SENSORS_AIR
                    compositeItem(CONSTANTS.ZOHO_SOIL_SENSE_3_AIR, 1, 'Soil-Sense')
                }
                else {
                    build_type = CONSTANTS.SOIL_SENSE_3_SENSORS_NO_AIR
                    compositeItem(CONSTANTS.ZOHO_SOIL_SENSE_3_NO_AIR, 1, 'Soil-Sense')
                }
            } else {
                build_type = CONSTANTS.SOIL_SENSE_DRY_WATER
                compositeItem(CONSTANTS.ZOHO_DRY_WATER_1_NO_AIR, 1, 'Soil-Sense')
            }
        } else if (newForm === 'optimizer') {
            build_type = CONSTANTS.OPTIMIZER_1_SENSOR
            compositeItem(CONSTANTS.ZOHO_OPTIMIZER, 1, 'Optimizer')
        } else {
            build_type = CONSTANTS.AG_1_SENSOR
            compositeItem(CONSTANTS.ZOHO_AG_SENSE, 1, 'Ag')
        }

        // Adding the stock of power (solar/battery) in zoho
        if (power === 'underground') {
            //batter, check counts
            power_infrastructure['Type'] = 'Underground'
            power_infrastructure['Number_of_tcm_packs'] = undergroundPacks.tcm
            power_infrastructure['Number_of_battery_packs'] = undergroundPacks.battery
            compositeItem(CONSTANTS.ZOHO_UNDERGROUND_TCM, undergroundPacks.tcm, 'Power - TCM')
            compositeItem(CONSTANTS.ZOHO_UNDERGROUND_BATTERY, undergroundPacks.battery, 'Power - Battery')
        } else if (power === 'existing') {
            power_infrastructure['Type'] = 'Unknown'
        } else if (power === 'instack') {
            power_infrastructure['Type'] = 'Instack'
            compositeItem(CONSTANTS.ZOHO_INSTACK_POWER, 1, 'Power - Instack')
        } else {
            // Solar, update the count directly
            power_infrastructure['Type'] = 'Solar'
            if (power === CONSTANTS.ZOHO_SOLAR_30W) {
                power_infrastructure['Power'] = '30 Watt'
            } else {
                power_infrastructure['Power'] = '60 Watt'
            }
            compositeItem(power, 1, 'Power')
        }
        let payload = {
            site,
            site_search: site.toUpperCase(),
            quantifier_id,
            quantifier_name: quantifier,
            tcm,
            sensors: sensor,
            electronic_test_date: testDate,
            notes,
            field_install_date: fieldDate,
            zoho_bundling_date: bundleDate,
            power_infrastructure,
            build_type,
            operational: true,
            time_inserted: new Date()
        }
        insertQuantifier(payload)
        navigate(CONSTANTS.PAGE_QUANTIFIER_VIEW)
    }

    async function compositeItem(id, number_of_stock, name) {
        // gets the composite item and updates its stock
        let serial = [quantifier_id]
        let zoho_token = ls.getLocalStorageItemZoho().token
        let headers = {
            'authorization': `Bearer ${props.token}`,
            'Zoho-Auth': `Zoho-oauthtoken ${zoho_token}`,
            'Target-URL': CONSTANTS.ZOHO_COMPOSITE_ITEM.replace(':item_id', id)
        }
        if (name.includes('Power')) {
            serial = [`${name}-${quantifier_id}`]
        }
        if (zoho_token) {
            setLoading(true)
            await axios.get(CONSTANTS.ZOHO_PROXY_SERVER, { headers })
                .then(async (response) => {
                    let stock = number_of_stock ? number_of_stock : 1
                    let line_items = response.data.composite_item.mapped_items.map((value) => {
                        let new_obj = {}
                        new_obj.item_id = value.item_id
                        new_obj.name = value.name
                        new_obj.quantity_consumed = parseFloat(value.quantity)
                        new_obj.account_id = value.inventory_account_id
                        new_obj.warehouse_id = "2857783000001722313"
                        return new_obj
                    })
                    let payload_bundle = {
                        "reference_number": `${quantifier_id}-${site}-${quantifier}-${name.replaceAll(" ", "")}`.slice(0, 49),
                        "date": new Date(bundleDate).toISOString().slice(0, 10),
                        "description": `${quantifier_id} - ${site.toLocaleUpperCase()} - ${quantifier} - ${name}`,
                        "composite_item_id": id,
                        "composite_item_name": response.data.composite_item.name,
                        "quantity_to_bundle": stock,
                        "line_items": line_items,
                        "is_completed": true,
                        "finished_product_serial_numbers": serial
                    }

                    headers['Target-URL'] = CONSTANTS.ZOHO_CREATE_BUNDLE_API
                    await axios.post(CONSTANTS.ZOHO_PROXY_SERVER, payload_bundle,
                        { headers }).then(() => setLoading(false))
                })
        }
    }

    async function getSites(token) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_API_URL}`, { headers })
            .then((response) => {
                let data = response.data
                data = data.sort((a, b) => {
                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
                    return 0;
                  });
                setSiteData(data)
                setSite(data[0].Name)
            })
    }
    async function getSensors(token) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_ALL_SENSORS_GET_API_URL}`, { headers })
            .then((response) => {
                let data = response.data ? response.data.data : []
                data = data.map((value, idx) => {
                    let mapped_Value = {}
                    if (value.most_recent) {
                        mapped_Value.leak_test = value.most_recent.leak_test
                        mapped_Value.coupling_serial_1 = value.most_recent.coupling_serial_1
                        mapped_Value.coupling_serial_2 = value.most_recent.coupling_serial_2
                        mapped_Value.sensor_housing_id = value.most_recent.sensor_housing_id
                        mapped_Value.tested_by = value.most_recent.tested_by
                        mapped_Value.tested_pressure = value.most_recent.tested_pressure
                        mapped_Value.water_test_date = value.most_recent.water_test_date
                        mapped_Value.notes = value.most_recent.notes
                    }
                    mapped_Value.sensor_id = value.sensor_id
                    mapped_Value.bb_label = value.bb_label
                    mapped_Value.time_inserted = value.time_inserted
                    mapped_Value.caliberation_test_date = value.caliberation_test_date
                    mapped_Value.id = `${value.sensor_id}${idx}${value.time_inserted}${value.caliberation_test_date}${value.most_recent}`
                    delete mapped_Value.versions
                    delete mapped_Value.most_recent
                    return mapped_Value
                })
                setSensorData(data)
            })
    }
    async function getQuantifierId(token, isSubmit) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_EQUIPMENT_COUNT_API_URL.replace(":type", "Q").replace(":year", new Date().getFullYear())}`,
            { headers })
            .then((response) => {
                let data = response.data ? response.data.data : []
                setLoading(false)
                setQuantifierId(data[0].id)
                if (isSubmit) {
                    handleSubmit(data[0].id)
                }
                
            })
    }
    async function insertQuantifier(data) {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        setLoading(true)
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_QUANTIFIER_INSERT_API_URL}`, data, { headers })
            .then(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        if (props.token) {
            setLoading(true)
            if (!props.isEdit) {
                getSites(props.token)
                getSensors(props.token)
                getQuantifierId(props.token)
            }
            else {
                setSiteData(props.siteData)
                setSensorData(props.sensorData)
                setQuantifierId(props.data.quantifier_id)
                setQuantifier(props.data.quantifier_name)
                setTCM(props.data.tcm)
                setNotes(props.data.notes)
                setFieldDate(props.data.field_install_date)
                setTestDate(props.data.electronic_test_date)
                setOperational(props.data.operational)
                setSite(props.data.site)
                setBundleDate(props.data.zoho_bundling_date ? props.data.zoho_bundling_date : props.data.time_inserted)
                let numberOfSensors = props.sensors.length
                if (numberOfSensors) {
                    props.sensors.forEach(element => {
                        if (element.board === 1) {
                            setSensor_1(element)
                        }
                        if (element.board === 2) {
                            setSensor_2(element)
                        }
                        if (element.board === 3) {
                            setSensor_3(element)
                        }
                    });
                }
                setLoading(false)
            }
        }
        // eslint-disable-next-line
    }, [props.token])
    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Grid container rowSpacing={5} columnSpacing={3} mt={2} alignItems="center" justify="center">
                    {
                        !props.isEdit &&
                        <Grid item xs={4}>
                            <FormControl sx={{
                                width: "50%"
                            }}>
                                <InputLabel id="form-factor-version-label">Form Factor</InputLabel>
                                <Select
                                    required
                                    id="form-factor-select"
                                    name="form-factor"
                                    labelId="form-factor-version-label"
                                    value={newForm}
                                    label="Form Factor"
                                    onChange={(e) => {
                                        setNewForm(e.target.value)
                                    }}
                                >
                                    <MenuItem value={'new'} key={1}>{'New'}</MenuItem>
                                    <MenuItem value={'old'} key={2}>{'Old'}</MenuItem>
                                    <MenuItem value={'optimizer'} key={3}>{'Optimizer'}</MenuItem>
                                    <MenuItem value={'ag'} key={4}>{'Ag Sense'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={4}>
                        <FormControl sx={{
                            width: "50%"
                        }}>
                            <InputLabel id="site-dropdown-label-quantifier">Site</InputLabel>
                            <Select
                                required
                                id="site-select-quantifier"
                                name="site"
                                labelId="site-dropdown-label-quantifier"
                                value={site}
                                label="Site"
                                onChange={handleChange}
                            >
                                {siteData && siteData.map(data =>
                                    <MenuItem value={data.Name} key={data._id}>{data.fullName}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                    </Grid>
                    <Grid item xs={4}>
                        <TextField required id="quantifier_name" name='quantifier_name' label="Quantifier Name" variant="outlined"
                            value={quantifier}
                            onChange={handleChange}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField required id="quantifier_id" name='quantifier_id' label="Quantifier Id"
                            variant="outlined" value={quantifier_id} disabled
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField required id="tcm" label="TCM" name='tcm' variant="outlined" value={tcm} onChange={handleChange}
                            sx={{
                                width: "50%"
                            }} />
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                required
                                label="Electronic Test Date"
                                labelId="sdate-dropdown-label"
                                value={testDate}
                                onChange={(newValue) => {
                                    setTestDate(newValue.startOf('day'));
                                    if (props.isEdit) {
                                        let new_data = props.data
                                        new_data.electronic_test_date = newValue.startOf('day')
                                        props.setData(new_data)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "50%"
                                    }} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                required
                                label="Field Install Date"
                                value={fieldDate}
                                onChange={(newValue) => {
                                    setFieldDate(newValue.startOf('day'));
                                    if (props.isEdit) {
                                        let new_data = props.data
                                        new_data.field_install_date = newValue.startOf('day')
                                        props.setData(new_data)
                                    }
                                }}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "50%"
                                    }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {  // can't edit bundle date in zoho
                    }
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                required
                                label="Zoho Bundle Date"
                                value={bundleDate}
                                disabled={props.isEdit}
                                onChange={(newValue) => {
                                    setBundleDate(newValue.startOf('day'));
                                }}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "50%"
                                    }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="sensor_1"
                            name="sensor_1"
                            options={sensorData}
                            value={sensor_1}
                            getOptionLabel={(option) => option.bb_label || option.sensor_id}
                            onChange={(e, value) => {
                                if (props.isEdit) {
                                    if (value) {
                                        value.board = 1
                                        props.setSensors([...props.sensors.filter(item => item.sensor_id !== sensor_1.sensor_id), value])
                                    } else {
                                        props.setSensors(props.sensors.filter(item => item.sensor_id !== sensor_1.sensor_id))
                                    }
                                }
                                setSensor_1(value)
                            }}
                            sx={{
                                width: '50%',
                                marginLeft: !props.isEdit ? "25%" : ""
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: "10%" }}
                                        checked={selected}
                                    />
                                    {`${option.sensor_id} ${option.bb_label ? option.bb_label: '' }`}
                                </li>
                            )}
                            renderInput={(params) =>
                            (
                                <TextField {...params} label="Sensor 1" />
                            )

                            }
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="sensor_2"
                            name="sensor_2"
                            options={sensorData}
                            value={sensor_2}
                            getOptionLabel={(option) => option.bb_label || option.sensor_id}
                            onChange={(e, value) => {
                                if (props.isEdit) {
                                    if (value) {
                                        value.board = 2
                                        props.setSensors([...props.sensors.filter(item => item.sensor_id !== sensor_2.sensor_id), value])
                                    } else {
                                        props.setSensors(props.sensors.filter(item => item.sensor_id !== sensor_2.sensor_id))
                                    }
                                }
                                setSensor_2(value)
                            }}
                            sx={{
                                width: '50%',
                                marginLeft: !props.isEdit ? "25%" : ""
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: "10%" }}
                                        checked={selected}
                                    />
                                    {`${option.sensor_id} ${option.bb_label ? option.bb_label: '' }`}
                                </li>
                            )}
                            renderInput={(params) =>
                            (
                                <TextField {...params} label="Sensor 2" />
                            )

                            }
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            id="sensor_3"
                            name="sensor_3"
                            options={sensorData}
                            value={sensor_3}
                            getOptionLabel={(option) => option.bb_label || option.sensor_id}
                            onChange={(e, value) => {
                                if (props.isEdit) {
                                    if (value) {
                                        value.board = 3
                                        props.setSensors([...props.sensors.filter(item => item.sensor_id !== sensor_3.sensor_id), value])
                                    } else {
                                        props.setSensors(props.sensors.filter(item => item.sensor_id !== sensor_3.sensor_id))
                                    }
                                }
                                setSensor_3(value)
                            }}
                            sx={{
                                width: '50%',
                                marginLeft: !props.isEdit ? "25%" : ""
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: "10%" }}
                                        checked={selected}
                                    />
                                    {`${option.sensor_id} ${option.bb_label ? option.bb_label: '' }`}
                                </li>
                            )}
                            renderInput={(params) =>
                            (
                                <TextField {...params} label="Sensor 3" />
                            )

                            }
                        />

                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="notes" name='notes' label="Notes" variant="outlined"
                            value={notes}
                            multiline
                            onChange={handleChange}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{
                            width: "50%"
                        }}>
                            <InputLabel id="operational-dropdown-label-quantifier">Operational</InputLabel>
                            <Select
                                required
                                id="operational-select-quantifier"
                                name="operational"
                                labelId="operational-dropdown-label-quantifier"
                                value={props.isEdit ? operational : true}
                                disabled={props.isEdit ? false : true}
                                label="Operational"
                                onChange={handleChange}
                            >
                                <MenuItem value={true} key={1}>{'True'}</MenuItem>
                                <MenuItem value={false} key={2}>{'False'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {
                        (!props.isEdit && newForm === 'old') && <Grid item xs={4}>
                            <FormControl sx={{
                                width: "50%"
                            }}>
                                <InputLabel id="air-fitting-dropdown-label-quantifier">Air Fittings</InputLabel>
                                <Select
                                    required
                                    id="air-fitting-select-quantifier"
                                    name="air-fitting"
                                    labelId="air-fitting-dropdown-label-quantifier"
                                    value={af}
                                    label="air-fitting"
                                    onChange={(e) => {
                                        setAf(e.target.value)
                                    }}
                                >
                                    <MenuItem value={true} key={1}>{'True'}</MenuItem>
                                    <MenuItem value={false} key={2}>{'False'}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {!props.isEdit && <Grid item xs={4}>
                        <FormControl sx={{
                            width: "50%"
                        }}>
                            <InputLabel id="power-dropdown-label-quantifier">Power</InputLabel>
                            <Select
                                required
                                id="power-select-quantifier"
                                name="power"
                                labelId="power-dropdown-label-quantifier"
                                value={power}
                                label="Power"
                                onChange={(e) => {
                                    setPower(e.target.value)
                                }}
                            >
                                <MenuItem value={CONSTANTS.ZOHO_SOLAR_30W} key={1}>{'Solar - 30W'}</MenuItem>
                                <MenuItem value={CONSTANTS.ZOHO_SOLAR_50W} key={2}>{'Solar - 50W'}</MenuItem>
                                <MenuItem value={"underground"} key={3}>{'Underground'}</MenuItem>
                                <MenuItem value={"existing"} key={4}>{'Already Exists'}</MenuItem>
                                <MenuItem value={"instack"} key={5}>{'Instack'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>}
                    {(power === 'underground') &&
                        <React.Fragment>
                            < Grid item xs={4}>
                                <FormControl sx={{
                                    width: "50%"
                                }}>
                                    <InputLabel id="BP-dropdown-label-quantifier">Battery Pack</InputLabel>
                                    <Select
                                        required
                                        id="BP-select-quantifier"
                                        name="BP"
                                        labelId="BP-dropdown-label-quantifier"
                                        value={undergroundPacks.battery}
                                        label="Battery Pack"
                                        onChange={(e) => {
                                            setUndergroundPacks(prev => ({
                                                ...prev,
                                                battery: e.target.value
                                            }))
                                        }}
                                    >
                                        <MenuItem value={1} key={1}>{'1'}</MenuItem>
                                        <MenuItem value={2} key={2}>{'2'}</MenuItem>
                                        <MenuItem value={3} key={3}>{'3'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            < Grid item xs={4}>
                                <FormControl sx={{
                                    width: "50%"
                                }}>
                                    <InputLabel id="TCM-dropdown-label-quantifier">TCM Pack</InputLabel>
                                    <Select
                                        required
                                        id="TCM-select-quantifier"
                                        name="TCM"
                                        labelId="TCM-dropdown-label-quantifier"
                                        value={undergroundPacks.tcm}
                                        label="TCM Pack"
                                        onChange={(e) => {
                                            setUndergroundPacks(prev => ({
                                                ...prev,
                                                tcm: e.target.value
                                            }))
                                        }}
                                    >
                                        <MenuItem value={1} key={1}>{'1'}</MenuItem>
                                        <MenuItem value={2} key={2}>{'2'}</MenuItem>
                                        <MenuItem value={3} key={3}>{'3'}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </React.Fragment>
                    }
                    {!props.isEdit && <Grid item xs={12}>
                        <Box m={2} pt={3}>
                            <Button variant="contained" onClick={()=> getQuantifierId(props.token, true)}
                                disabled={(!site || !quantifier || !tcm || !sensor_1 || !testDate)}>Submit</Button>
                        </Box>
                    </Grid>}
                </Grid>}
        </React.Fragment >
    )
}