import axios from 'axios';
import Title from './Title';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import CONSTANTS from '../../helpers/constants';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';


export default function TopInStock(props) {
    const [loading, setLoading] = useState(false)
    const [soilSense, setSoilSense] = useState({})
    const [expanded, setExpanded] = useState(false);
    const [sensorHousing, setSensorHousing] = useState({})
    const [sensorBoards, setSensorBoards] = useState({})
    const [solar30W, setSolar30W] = useState({})
    const [solar50W, setSolar50W] = useState({})

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    async function getCompositeItem(token, zohoToken, composite_item_id, type, props) {
        setLoading(true)
        let headers = {
            'authorization': `Bearer ${token}`,
            'Zoho-Auth': `Zoho-oauthtoken ${zohoToken}`,
            'Target-URL': CONSTANTS.ZOHO_COMPOSITE_ITEM.replace(':item_id', composite_item_id)
        }
        await axios.get(`${CONSTANTS.ZOHO_PROXY_SERVER}`,
            {
                headers,
            })
            .then(response => {
                let data = response.data
                let new_data = {}
                let can_built = 10000
                let current_max = 0
                let line_items = data.composite_item.mapped_items.map((value) => {
                    let new_obj = {}
                    new_obj.item_id = value.item_id
                    new_obj.name = value.name
                    new_obj.quantity_consumed = parseFloat(value.quantity)
                    new_obj.stock_on_hand = parseFloat(value.stock_on_hand)
                    new_obj.account_id = value.inventory_account_id
                    new_obj.quantity = parseFloat(value.quantity)
                    if (parseFloat(value.stock_on_hand) > 0) {
                        current_max = Math.ceil(parseFloat(value.stock_on_hand) / parseFloat(value.quantity))
                    } else {
                        current_max = 0
                    }
                    if (current_max < can_built) {
                        can_built = current_max
                    }
                    return new_obj
                })
                new_data.name = data.composite_item.name
                new_data.rate = data.composite_item.rate
                new_data.stock_on_hand = can_built
                new_data.last_modified_time = new Date(data.composite_item.last_modified_time).toLocaleString('en-US', { timeZone: 'America/Regina' })
                new_data.items = line_items
                if (type === 'SH') {
                    setSensorHousing(new_data)
                } else if (type === 'SS') {
                    props.setQuantInventory({
                        can_built,
                        in_hand: data.composite_item.stock_on_hand,
                    })
                    setSoilSense(new_data)
                } else if (type === 'SB') {
                    setSensorBoards(new_data)
                } else if (type === 'S3') {
                    setSolar30W(new_data)
                } else {
                    setSolar50W(new_data)
                }
                setLoading(false)
            })
    }
    useEffect(() => {
        if (props.token && Object.keys(soilSense).length === 0 && Object.keys(sensorHousing).length === 0 &&
            Object.keys(sensorBoards).length === 0 && Object.keys(solar30W).length === 0 &&
            Object.keys(solar50W).length === 0) {
            // VO - Sensor Housing
            getCompositeItem(props.token, props.zohoToken, CONSTANTS.ZOHO_SENSOR_HOUSING_NEW, 'SH', props)
            // Coupled Sensor Board
            getCompositeItem(props.token, props.zohoToken, CONSTANTS.ZOHO_SENSOR_BOARDS_NEW, 'SB', props)
            // VO - Soil Sense
            getCompositeItem(props.token, props.zohoToken, CONSTANTS.ZOHO_SOIL_SENSE_3_NEW, 'SS', props)
            // Solar 30 Watt
            getCompositeItem(props.token, props.zohoToken, CONSTANTS.ZOHO_SOLAR_30W, 'S3', props)
            // Solar 50 Watt
            getCompositeItem(props.token, props.zohoToken, CONSTANTS.ZOHO_SOLAR_50W, 'S5', props)
        }
        // eslint-disable-next-line
    }, [])
    return (
        <React.Fragment>
            <Title> Soil Sense Stock</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            <Stack direction="row" spacing={10} m={1}>
                <Typography width={"33%"} variant="h6">
                    Inventory Item
                </Typography>
                <Typography width={"33%"} variant="h6">
                    Can be Built/In Stock
                </Typography>
                <Typography width={"33%"} variant="h6">
                    Last Modified/Items Needed
                </Typography>
            </Stack>
            {(!loading && sensorHousing) &&
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} key={1}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '33%' }}>
                            {sensorHousing.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{sensorHousing.stock_on_hand}</Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{sensorHousing.last_modified_time}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(sensorHousing.items && sensorHousing.items.length) &&
                            sensorHousing.items.map((item, idx) =>
                                <React.Fragment key={idx}>
                                    <Stack direction="row" spacing={10}>
                                        <Typography width={"33%"}>
                                            {item.name}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.stock_on_hand}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.quantity}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                </React.Fragment>
                            )
                        }
                    </AccordionDetails>
                </Accordion>}
            {(!loading && sensorBoards) &&
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} key={2}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography sx={{ width: '33%' }}>
                            {sensorBoards.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{sensorBoards.stock_on_hand}</Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{sensorBoards.last_modified_time}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(sensorBoards.items && sensorBoards.items.length) &&
                            sensorBoards.items.map((item, idx) =>
                                <React.Fragment key={idx}>
                                    <Stack direction="row" spacing={10}>
                                        <Typography width={"33%"}>
                                            {item.name}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.stock_on_hand}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.quantity}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                </React.Fragment>
                            )
                        }
                    </AccordionDetails>
                </Accordion>}
            {(!loading && soilSense) &&
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} key={3}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography sx={{ width: '33%' }}>
                            {soilSense.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{soilSense.stock_on_hand}</Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{soilSense.last_modified_time}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(soilSense.items && soilSense.items.length) &&
                            soilSense.items.map((item, idx) =>
                                <React.Fragment key={idx}>
                                    <Stack direction="row" spacing={10}>
                                        <Typography width={"33%"}>
                                            {item.name}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.stock_on_hand}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.quantity}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                </React.Fragment>
                            )
                        }
                    </AccordionDetails>
                </Accordion>}
            {(!loading && solar30W) &&
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} key={4}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography sx={{ width: '33%' }}>
                            {solar30W.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{solar30W.stock_on_hand}</Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{solar30W.last_modified_time}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(solar30W.items && solar30W.items.length) &&
                            solar30W.items.map((item, idx) =>
                                <React.Fragment key={idx}>
                                    <Stack direction="row" spacing={10}>
                                        <Typography width={"33%"}>
                                            {item.name}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.stock_on_hand}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.quantity}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                </React.Fragment>
                            )
                        }
                    </AccordionDetails>
                </Accordion>}
            {(!loading && solar50W) &&
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} key={5}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel5bh-content"
                        id="panel5bh-header"
                    >
                        <Typography sx={{ width: '33%' }}>
                            {solar50W.name}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{solar50W.stock_on_hand}</Typography>
                        <Typography sx={{ color: 'text.secondary', width: '33%' }}>{solar50W.last_modified_time}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(solar50W.items && solar50W.items.length) &&
                            solar50W.items.map((item, idx) =>
                                <React.Fragment key={idx}>
                                    <Stack direction="row" spacing={10}>
                                        <Typography width={"33%"}>
                                            {item.name}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.stock_on_hand}
                                        </Typography>
                                        <Typography width={"33%"}>
                                            {item.quantity}
                                        </Typography>
                                    </Stack>
                                    <Divider />
                                </React.Fragment>
                            )
                        }
                    </AccordionDetails>
                </Accordion>}
        </React.Fragment>
    );
}


