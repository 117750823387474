import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import React, { useState } from "react";
import Button from '@mui/material/Button';
import CONSTANTS from '../../helpers/constants';
import TextField from '@mui/material/TextField';
import LocalStorage from "../Login/local-storage";
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


export default function ViewQuantifier(props) {
    let ls = new LocalStorage()
    const [search, setSearch] = useState({
        date: "",
        search: ""
    })
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)

    let columns = [
        {
            field: 'bundle_id',
            headerName: 'Bundle Id',
            flex: 1,
        },
        {
            field: 'composite_item_name',
            headerName: 'Composite Item',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'reference_number',
            headerName: 'Bundle Reference',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'quantity_to_bundle',
            headerName: 'Bundle Quantity',
            flex: 0.5,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
        {
            field: 'total',
            headerName: 'Total',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? `$${value}` : '-'}`,
        },
        {
            field: 'date',
            headerName: 'Created Date',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toDateString() : '-'}`,
        },
        {
            field: 'created_by',
            headerName: 'Created By',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
        },
    ]

    async function getZohoBundles() {
        setLoading(true)
        setResults([])
        let zoho_token = ls.getLocalStorageItemZoho().token
        let headers = {
            'authorization': `Bearer ${props.token}`,
            'Zoho-Auth': `Zoho-oauthtoken ${zoho_token}`,
        }
        const composite_item_names = [CONSTANTS.ZOHO_SOIL_SENSE_3_NEW, CONSTANTS.ZOHO_SOIL_SENSE_2_NEW, CONSTANTS.ZOHO_WELL_SENSOR_NEW, 
            CONSTANTS.ZOHO_AG_SENSE, CONSTANTS.ZOHO_OPTIMIZER, CONSTANTS.ZOHO_INSTACK_POWER, CONSTANTS.ZOHO_UNDERGROUND_BATTERY, 
            CONSTANTS.ZOHO_UNDERGROUND_TCM, CONSTANTS.ZOHO_SOLAR_30W, 
            CONSTANTS.ZOHO_SOLAR_50W]
        let i = 0
        for (i; i < composite_item_names.length; i++) {
            let new_obj = {}
            let bundles = []
            headers['Target-URL'] = CONSTANTS.ZOHO_BUNDLING_HISTORY.replace(':composite_item_id', composite_item_names[i])
            await axios.get(CONSTANTS.ZOHO_PROXY_SERVER, { headers })
                .then((response) => {
                    bundles = response.data.bundles.map((value) => {
                        if (value) {
                            new_obj = {}
                            new_obj.bundle_id = value.bundle_id
                            new_obj.reference_number = value.reference_number
                            new_obj.date = value.date
                            new_obj.description = value.description
                            new_obj.composite_item_name = value.composite_item_name
                            new_obj.quantity_to_bundle = value.quantity_to_bundle
                            new_obj.total = value.total
                            new_obj.created_by = value.created_by_name
                            new_obj.id = `${value.bundle_id}-${value.reference_number}`
                            if (search.search && search.date) {
                                if (value.description.includes(search.search.toUpperCase())) {
                                    if (new Date(value.date).getTime() > new Date(search.date)) {
                                        return new_obj
                                    }
                                }
                            } else {
                                if (search.date) {
                                    if (new Date(value.date).getTime() > new Date(search.date)) {
                                        return new_obj
                                    }
                                } else {
                                    if (value.description.includes(search.search.toUpperCase())) {
                                        return new_obj
                                    }
                                }
                            }
                        }
                        new_obj = null
                        return new_obj
                    })
                    bundles = bundles.filter((value) => value)
                    setResults(values => values.concat(bundles))
                    bundles = []
                })
        }
        // All searched
        if (i === composite_item_names.length) {
            setLoading(false)
        }
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: 'SoilSense-Reports'
                }} />
            </GridToolbarContainer>
        );
    }

    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        setSearch(prev => ({
            ...prev,
            [name]: value
        }))
    };

    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            <Box component="span" sx={{ p: 2, borderColor: '#EBF5FB', border: '1px dashed', m: 1 }}>
                <Grid container rowSpacing={2} columnSpacing={2} mt={1} mb={1}>
                    <Grid item xs={4}>
                        <TextField id="quantifier-search-field" name='search' label="Search" variant="outlined"
                            value={search.search}
                            onChange={handleChange}
                            sx={{
                                width: "50%"
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    getZohoBundles()
                                }
                            }}

                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Bundled After"
                                value={search.date}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setSearch(prev => ({
                                            ...prev,
                                            date: new Date(newValue.startOf('day').format('YYYY-MM-DD'))
                                        }))
                                    } else {
                                        setSearch(prev => ({
                                            ...prev,
                                            date: ''
                                        }))
                                    }
                                }}
                                componentsProps={{
                                    actionBar: { actions: ['clear'] },
                                }}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "50%"
                                    }} />}
                            />
                        </LocalizationProvider>

                    </Grid>
                    <Grid item xs={2} mt={2}>
                        <Button variant="outlined" color="primary" startIcon={<SearchIcon />}
                            onClick={() => {
                                getZohoBundles()
                            }}
                            disabled={!search.search && !search.date}
                        >Search</Button>
                    </Grid>
                </Grid>
            </Box>
            <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                <Grid item xs={12} mt={2}>
                    <DataGrid
                        rows={results}
                        columns={columns}
                        autoHeight={true}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        loading={loading}
                        columnVisibilityModel={{
                            // Hide these columns
                            bundle_id: false,
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}