import axios from 'axios';
import S3 from 'aws-sdk/clients/s3';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import CONSTANTS from '../../helpers/constants';
import TextField from '@mui/material/TextField';
import ImageList from '@mui/material/ImageList';
import InputLabel from '@mui/material/InputLabel';
import LocalStorage from "../Login/local-storage";
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import GoogleIcon from '@mui/icons-material/Google';
import UploadIcon from '@mui/icons-material/Upload';
import OutlinedInput from '@mui/material/OutlinedInput';
import ImageListItem from '@mui/material/ImageListItem';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import raw from '../Reports/Field_Report_Template_empty.pdf';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const s3 = new S3({
    "accessKeyId": process.env.REACT_APP_ACCESS_KEY,
    "secretAccessKey": process.env.REACT_APP_SECRET_ACCESS_KEY,
    "region": process.env.REACT_APP_REGION
});

export default function AddReport(props) {
    let ls = new LocalStorage()
    const navigate = useNavigate();
    const [notes, setNotes] = useState({
        site: '',
        scope: '',
        hours: '',
        distance_travelled: '',
        work_performed: '',
        outcomes_issues: '',
        time_arrival: "",
        time_departure: "",
        date: "",
        general_notes: "",
    })
    const [caption, setCaption] = useState({})
    const [loading, setLoading] = useState(false)
    const [siteData, setSiteData] = useState({})
    const [loadingPhotos, setLoadingPhotos] = useState({})
    const pictures_keys = [
        {
            key: 'general_pictures',
            aws: 'general_pictures_aws',
            name: 'General Pictures'
        },
        {
            key: 'site_pictures',
            aws: 'site_pictures_aws',
            name: 'Site Pictures'
        },
        {
            key: 'power_pictures',
            aws: 'power_pictures_aws',
            name: 'Power Pictures'
        },
        {
            key: 'gateway_pictures',
            aws: 'gateway_pictures_aws',
            name: 'Gateway Pictures'
        },
        {
            key: 'quant_pictures',
            aws: 'quant_pictures_aws',
            name: 'Quantifier Pictures'
        },
        {
            key: 'distributor_power',
            aws: 'distributor_power_aws',
            name: 'Distributor Pictures'
        },
        {
            key: 'distributor_injection',
            aws: 'distributor_injection_aws',
            name: 'Distributor Injection Pictures'
        },
        {
            key: 'distributor_water',
            aws: 'distributor_water_aws',
            name: 'Distributor Water Pictures'
        }
    ]

    let window_location = window.location.href.toString()
    window_location = window_location.split('/')

    async function getNotes(token, id) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_GET_ONE_API.replace(":id", id)}`, { headers })
            .then(async (response) => {
                setNotes(response.data)
                await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_API_URL}/${response.data.site}`, { headers })
                    .then((result) => {
                        setSiteData(result.data[0])
                    })
            })
    }

    async function getSpecificReport(token, id, site) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.REPORTS_GET_ONE_API.replace(":id", id)}`, { headers })
            .then(async (response) => {
                setNotes(response.data)
            })
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_API_URL}/${site}`, { headers })
            .then((result) => {
                setSiteData(result.data[0])
        })
    }

    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        setNotes(prev => ({
            ...prev,
            [name]: value
        }))
    };
    useEffect(() => {
        if (props.token && window_location[6] !== ':notesId') {
            if (!window_location[6].includes('?')) {
                setLoading(true)
                getNotes(props.token, window_location[6])
                setLoading(false)
            } else {
                //get data from reports collection
                let split_parms = window_location[6].split('?')
                setLoading(true)
                getSpecificReport(props.token, split_parms[0], split_parms[1].replace('site=', ''))
                setLoading(false)
            }
        }
        // eslint-disable-next-line
    }, [window_location[6]])
    const saveFile = (e) => {
        e.preventDefault()
        loadFile('SAVE')
    }
    const loadFile = (event_value) => {
        fetch(raw)
            .then(res => res.arrayBuffer())
            .then(async (existingPdfBytes) => {
                const pdfDoc = await PDFDocument.load(existingPdfBytes)
                const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
                const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
                const pages = pdfDoc.getPages()
                pdfDoc.setTitle('Field_report')
                const form = pdfDoc.getForm()
                const firstPage = pages[0]

                const to = form.createTextField('project.to')
                let text = ''
                if (siteData.consultants && siteData.consultants.length) {
                    text = `${siteData.fcl_advisor ? siteData.fcl_advisor.name : ""}, Federated Co-operatives Limited (FCL)\n`
                    text += siteData.consultants.map(item => item.name).join(', ')
                    text += `, (${siteData.consultants[0].firm})`
                }
                to.setText(text)
                to.addToPage(firstPage, { x: 140, y: 625, height: 30, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                to.enableMultiline()
                to.setFontSize(10)

                const from = form.createTextField('project.from')
                from.setText('Steven Siciliano')
                from.addToPage(firstPage, { x: 140, y: 605, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                from.setFontSize(10)

                const cc = form.createTextField('project.cc')
                cc.setText('Chelsea Voinorosky, Amy Jimmo')
                cc.addToPage(firstPage, { x: 140, y: 585, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                cc.setFontSize(10)

                const date_submitted = form.createTextField('project.date_submitted')
                date_submitted.setText(new Date().toDateString())
                date_submitted.addToPage(firstPage, { x: 140, y: 565, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                date_submitted.setFontSize(10)

                const subject = form.createTextField('project.subject')
                subject.setText(`Field Report For ${siteData.fullName} (#${siteData.fcl_number})`)
                subject.addToPage(firstPage, { x: 140, y: 545, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFontBold })
                subject.setFontSize(10)

                firstPage.drawText('Project Location:', { x: 90, y: 500, size: 12, font: helveticaFontBold })

                const projectLocation = form.createTextField('project.location')
                projectLocation.setText(siteData.address ? siteData.address : '-')
                projectLocation.addToPage(firstPage, { x: 210, y: 495, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                projectLocation.setFontSize(12)

                firstPage.drawText('Field Dates:', { x: 90, y: 475, size: 12, font: helveticaFontBold })
                const fieldDates = form.createTextField('field.dates')
                fieldDates.setText(new Date(notes.date).toDateString('YYYY-MM-DD'))
                fieldDates.addToPage(firstPage, { x: 210, y: 470, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                fieldDates.setFontSize(12)

                firstPage.drawText('Scope of Work:', { x: 90, y: 450, size: 12, font: helveticaFontBold })
                const scope = form.createTextField('field.scope')
                scope.setText(notes.scope)
                scope.addToPage(firstPage, { x: 210, y: 445, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                scope.setFontSize(12)

                firstPage.drawText('Hours (on Site):', { x: 90, y: 425, size: 12, font: helveticaFontBold })
                const hours = form.createTextField('field.hours')
                let hours_travlled_value = parseFloat(notes.hours)
                let hours_unit = 'hr'
                let minutes = 0
                if (hours_travlled_value < 1) {
                    minutes = hours_travlled_value * 60
                    hours_unit = 'min'
                    hours.setText(`${minutes} ${hours_unit} x ${notes.field_persons.length} people`)
                } else {
                    let minutes = hours_travlled_value - Math.floor(hours_travlled_value)
                    hours_travlled_value = hours_travlled_value - minutes
                    minutes *= 60
                    minutes = Math.ceil(minutes)
                    hours.setText(`${hours_travlled_value} ${hours_unit}, ${minutes} min x ${notes.field_persons.length} people`)
                }
                hours.addToPage(firstPage, { x: 210, y: 420, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                hours.setFontSize(12)

                firstPage.drawText('KM (Travelled):', { x: 90, y: 400, size: 12, font: helveticaFontBold })
                const distance_travelled = form.createTextField('field.distance_travelled')
                distance_travelled.setText(`${notes.distance_travelled * 2} km round trip x ${notes.field_persons.length} people`)
                distance_travelled.addToPage(firstPage, { x: 210, y: 395, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                distance_travelled.setFontSize(12)

                firstPage.drawText('Hours (Travelled):', { x: 90, y: 375, size: 12, font: helveticaFontBold })
                const hoursTravelling = form.createTextField('field.travelling_hours')
                hours_travlled_value = (parseInt(notes.distance_travelled * 2) / 90).toFixed(2)
                hours_unit = 'hr'
                minutes = 0
                if (hours_travlled_value < 1) {
                    minutes = hours_travlled_value * 60
                    hours_unit = 'min'
                    hoursTravelling.setText(`${minutes} ${hours_unit} x ${notes.field_persons.length} people`)
                } else {
                    let minutes = hours_travlled_value - Math.floor(hours_travlled_value)
                    hours_travlled_value = hours_travlled_value - minutes
                    minutes *= 60
                    minutes = Math.ceil(minutes)
                    hoursTravelling.setText(`${hours_travlled_value} ${hours_unit}, ${minutes} min x ${notes.field_persons.length} people`)
                }
                hoursTravelling.addToPage(firstPage, { x: 210, y: 370, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                hoursTravelling.setFontSize(12)

                firstPage.drawText('Field Personal:', { x: 90, y: 350, size: 12, font: helveticaFontBold })
                const fieldPersonal = form.createTextField('field.personal')
                let names = notes.field_persons.map(name => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
                fieldPersonal.setText(names.join(", "))
                fieldPersonal.addToPage(firstPage, { x: 210, y: 345, height: 18, width: 300, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                fieldPersonal.setFontSize(12)

                let work_lines = notes.work_performed ? notes.work_performed.split(/\r?\n/) : []
                let tasks = work_lines.join("\n")
                let work_lines_length = work_lines.length
                // to add lines if the inputed line is more than the width of the box.
                work_lines.forEach(element => {
                    if (element.length > 70) {
                        let value = element.length
                        while (value > 70) {
                            work_lines_length += 1
                            value -= 70
                        }
                    }
                });
                // adding 1 line if the field is empty
                work_lines_length = work_lines_length === 0 ? 1 : work_lines_length
                let height = (work_lines_length) * 15
                let y_remove = work_lines_length * 15
                let current_y = 335 - y_remove
                firstPage.drawText('Work Performed:', { x: 90, y: 320, size: 12, font: helveticaFontBold })
                const work_performed = form.createTextField('field.work_performed')
                work_performed.setText(tasks)
                work_performed.addToPage(firstPage, { x: 210, y: current_y, width: 330, height: height, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                work_performed.enableMultiline()
                work_performed.setFontSize(12)

                let total_lines = work_lines_length
                let issue_lines = notes.outcomes_issues ? notes.outcomes_issues.split(/\r?\n/) : []
                let issues = issue_lines.join("\n")
                work_lines_length = issue_lines.length
                issue_lines.forEach(element => {
                    if (element.length > 70) {
                        let value = element.length
                        while (value > 70) {
                            work_lines_length += 1
                            value -= 70
                        }
                    }
                });
                total_lines += work_lines_length
                let count = 1
                firstPage.drawText(count.toString(), { x: firstPage.getWidth() / 2, y: firstPage.getHeight() / 50, size: 12, font: helveticaFont })
                count += 1
                if (total_lines <= 18) {
                    // adding 1 line if the field is empty
                    work_lines_length = work_lines_length === 0 ? 1 : work_lines_length
                    let heading_height = current_y - 28
                    height = (work_lines_length) * 15
                    y_remove = work_lines_length * 15
                    current_y = current_y - y_remove - 15
                    firstPage.drawText('Outcomes/Issues: ', { x: 90, y: heading_height, size: 12, font: helveticaFontBold })
                    const issues_field = form.createTextField('field.issues')
                    issues_field.setText(issues)
                    issues_field.addToPage(firstPage, { x: 210, y: current_y, width: 330, height: height, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                    issues_field.enableMultiline()
                    issues_field.setFontSize(12)
                } else {
                    const newPage = pdfDoc.addPage([613, 841.89])
                    work_lines_length = work_lines_length === 0 ? 1 : work_lines_length
                    height = (work_lines_length) * 15
                    newPage.drawText('Outcomes/Issues: ', { x: 90, y: 750, size: 12, font: helveticaFontBold })
                    const issues_field = form.createTextField('field.issues')
                    issues_field.setText(issues)
                    issues_field.addToPage(newPage, { x: 210, y: 765 - height, width: 330, height: height, borderWidth: 0, backgroundColor: rgb(1, 1, 1), font: helveticaFont })
                    issues_field.enableMultiline()
                    issues_field.setFontSize(12)
                    let y = newPage.getHeight() / 4
                    newPage.drawText(count.toString(), { x: newPage.getWidth() / 2, y: y - 150, size: 12, font: helveticaFont })
                    count += 1
                }

                let key = ''
                let x = 0
                let y = 0
                let payload = {}
                let caption_obj = {}
                for (key in caption) {
                    if (caption[key].buffer) {
                        const jpgImage = await pdfDoc.embedJpg(caption[key].buffer);
                        const jpgDims = jpgImage.scaleToFit(600, 800)
                        const newPage = pdfDoc.addPage([613, 841.89])
                        if (jpgDims.width < jpgDims.height) {
                            x = newPage.getWidth() / 2 - jpgDims.width / 2
                            y = newPage.getHeight() / 2 - jpgDims.height / 2 + 50
                            newPage.drawImage(jpgImage, {
                                x: x,
                                y: y,
                                width: jpgDims.width,
                                height: jpgDims.height,
                            })
                        } else {
                            x = newPage.getWidth() / 2 - jpgDims.height / 2
                            y = newPage.getHeight() / 2 - jpgDims.width / 2 + 50
                            newPage.drawImage(jpgImage, {
                                x: x,
                                y: y,
                                width: jpgDims.height,
                                height: jpgDims.width,
                            })
                        }
                        newPage.drawText(wrapText(caption[key].value, 475, helveticaFont, 12), { x: x, y: y - 20, size: 12, font: helveticaFont })
                        newPage.drawText(count.toString(), { x: newPage.getWidth() / 2, y: y - 150, size: 12, font: helveticaFont })
                        count += 1
                        caption_obj[caption[key].key] = {
                            value: caption[key].value,
                            key: caption[key].key
                        }
                    }
                }

                const pdfBytes = await pdfDoc.save()
                const bytes = new Uint8Array(pdfBytes);

                if (event_value === 'SAVE') {
                    let date = new Date()
                    let datestring = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " +
                        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
                    payload.site = notes.site
                    payload.url = `${notes.site}/${datestring}.pdf`
                    payload.caption = caption_obj
                    // payload = {
                        // site: notes.site,
                        // user_name: ls.getLocalStorageItemZoho().user_name,
                        // user_id: ls.getLocalStorageItemZoho().user_id,
                        // url: `${notes.site}/${datestring}.pdf`,
                    // }
                    let params = {
                        Bucket: process.env.REACT_APP_BUCKET_REPORTS,
                        Key: payload.url,
                        Body: new Blob([bytes], { type: 'application/pdf' })
                    }
                    // uploading the report to aws
                    setLoading(true)
                    try {
                        const stored = await s3.upload(params).promise()
                        payload = {...payload, ...notes}
                        payload.user_name = ls.getLocalStorageItemZoho().user_name
                        payload.user_id = ls.getLocalStorageItemZoho().user_id
                        Object.keys(notes).forEach((key)=> {
                            if (key.includes('aws')) {
                                delete payload[key]
                            }
                        })
                        delete payload.id
                        delete payload._id
                        payload.user_name = ls.getLocalStorageItemZoho().user_name
                        payload.user_id = ls.getLocalStorageItemZoho().user_id
                        if (stored) {
                            saveReportData(payload)
                        }
                    } catch (err) {
                        console.log(err)
                    }
                } else {
                    const fileURL = window.URL.createObjectURL(new Blob([bytes], { type: "application/pdf" }))
                    window.open(fileURL)
                }
            });
    }

    const downloadImageAWS = (type) => {
        // setLoading(true)
        let payload = {
            key: notes[type],
            type,
            _id: notes._id
        }
        setLoadingPhotos(prev => ({
            ...prev,
            [type]: true
        }))
        getDataAWS(payload, type)
    }

    const wrapText = (text, width, font, fontSize) => {
        const words = text.split(' ');
        let line = '';
        let result = '';
        for (let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const testWidth = font.widthOfTextAtSize(testLine, fontSize);
            if (testWidth > width) {
                result += line + '\n';
                line = words[n] + ' ';
            } else {
                line = testLine;
            }
        }
        result += line;
        return result;
    }

    async function getDataAWS(payload, type) {
        let results = []
        let obj = {}
        let current_buffer = {}
        let caption_result = {}
        for (let i = 0; i < payload.key.length; i++) {
            obj = s3.getSignedUrl('getObject', {
                Bucket: 'ems-notes-images',
                Key: payload.key[i],
                Expires: 84600
            })
            current_buffer = await fetch(obj).then(res => res.arrayBuffer())
            results.push({
                obj,
                buffer: current_buffer,
                key: payload.key[i]
            })
            if (notes.caption && payload.key[i] in notes.caption) {
                caption_result[obj] = {
                    value: notes.caption[payload.key[i]].value,
                    buffer: current_buffer,
                    key: payload.key[i]
                }
            }
        }
        if (Object.keys(caption_result).length) {
            setCaption(prev => ({
                ...prev,
                ...caption_result
            }))
        }
        setNotes(prev => ({
            ...prev,
            [`${type}_aws`]: results
        }))

        setLoadingPhotos(prev => ({
            ...prev,
            [type]: false
        }))
        setLoading(false)
    }

    async function saveReportData(payload) {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.REPORT_ADD_API}`, payload, { headers }).then(() => {
            setLoading(false)
            navigate(CONSTANTS.PAGE_FIELD_REPORTS_VIEW)
        })
    }
    return (
        <React.Fragment>
            <Stack direction="row" spacing={0.3} sx={{ cursor: "Pointer", alignSelf: 'center' }} onClick={(e) =>
                window.open(`https://www.google.com/maps/search/${siteData.lat}, ${siteData.lng}`, '_blank')}>
                <Title>{props.title}</Title>
                <GoogleIcon sx={{ color: 'blue', alignSelf: 'center' }} />
                <NorthEastIcon sx={{ color: 'green', alignSelf: 'center' }} />
            </Stack>
            {
                loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {
                !loading &&
                <Grid container rowSpacing={5} columnSpacing={3} mt={2} alignItems="center" justify="center">
                    <Grid item xs={4}>
                        <TextField id="site" name='site' label="Site" variant="outlined"
                            value={notes.site}
                            disabled={true}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TimePicker
                                required
                                label="Time of Site Arrival"
                                value={notes.time_arrival}
                                disabled={true}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "50%"
                                    }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TimePicker
                                required
                                label="Time of Site Departure"
                                defaultValue={notes.time_departure}
                                disabled={true}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "50%"
                                    }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Date of Visit"
                                defaultValue={notes.date}
                                disabled={true}
                                renderInput={(params) => <TextField {...params}
                                    sx={{
                                        width: "50%"
                                    }} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="hours" name='hours' label="Hours on Site" variant="outlined"
                            value={notes.hours}
                            onChange={handleChange}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="distance_travelled" name='distance_travelled' label="Km Travelled" variant="outlined"
                            value={notes.distance_travelled}
                            onChange={handleChange}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="scope" name='scope' label="Scope" variant="outlined"
                            multiline
                            value={notes.scope}
                            onChange={handleChange}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    {
                        (notes.type === 'distributor' || notes.type === 'quantifier/distributor') &&
                        <React.Fragment>
                            <Grid item xs={4}>
                                <TextField id="distributor_location" name='distributor_location' label="Distributor Location" variant="outlined"
                                    multiline
                                    placeholder='Describe Location'
                                    value={notes.distributor.location}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="water_connection" name='water_connection' label="Water Connection" variant="outlined"
                                    placeholder='Tap, Well, Notes !'
                                    multiline
                                    value={notes.distributor.water_connection}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="injection_point" name='injection_point' label="Injection Point" variant="outlined"
                                    multiline
                                    placeholder='Notes'
                                    value={notes.distributor.injection_point}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            {
                                (notes.reason === 'install') &&
                                <React.Fragment>
                                    <Grid item xs={4}>
                                        <FormControl sx={{
                                            width: "50%"
                                        }}>
                                            <InputLabel id="field-notes-power-dropdown">Power (Distributor)</InputLabel>
                                            <Select
                                                multiple
                                                id="field-notes-power"
                                                name="power"
                                                labelId="field-notes-power-dropdown"
                                                value={notes.distributor.power}
                                                disabled={true}
                                                input={<OutlinedInput label="Power (Distributor)" />}
                                            >
                                                <MenuItem value={'underground'} key={1}>{'Underground'}</MenuItem>
                                                <MenuItem value={'solar'} key={2}>{'Solar'}</MenuItem>
                                                <MenuItem value={'hardwired'} key={3}>{'Hardwired'}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField id="power_notes" name='power_notes' label="Distributor Power Notes" variant="outlined"
                                            multiline
                                            value={notes.distributor.power_notes ? notes.distributor.power_notes : ""}
                                            disabled={true}
                                            sx={{
                                                width: "50%"
                                            }}
                                        />
                                    </Grid>
                                </React.Fragment>
                            }
                            <Grid item xs={4}>
                                <TextField id="carbon_bags" name='carbon_bags' label="Carbon Bags" variant="outlined"
                                    multiline
                                    placeholder='Notes'
                                    value={notes.distributor.carbon_bags}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="equipment_used" name='equipment_used' label="Equipment Used" variant="outlined"
                                    multiline
                                    placeholder='Notes'
                                    value={notes.distributor.equipment_used}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="settings" name='settings' label="Distributor Settings" variant="outlined"
                                    multiline
                                    placeholder='Notes'
                                    value={notes.distributor.settings}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="triply_tank" name='triply_tank' label="Triply Tank" variant="outlined"
                                    multiline
                                    placeholder='Measurement'
                                    value={notes.distributor.triply_tank}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="iron_citrate" name='iron_citrate' label="Iron Citrate" variant="outlined"
                                    multiline
                                    placeholder='Measurement'
                                    value={notes.distributor.iron_citrate}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="injection_settings" name='injection_settings' label="Injection Settings" variant="outlined"
                                    multiline
                                    placeholder='Timer for 6 hours on off?'
                                    value={notes.distributor.injection_settings}
                                    disabled={true}
                                    sx={{
                                        width: "50%"
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item xs={4}>
                        <TextField id="general_notes" name='general_notes' label="General Notes" variant="outlined"
                            multiline
                            value={notes.general_notes ? notes.general_notes : ""}
                            disabled={true}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="power_notes" name='power_notes' label="Power Notes" variant="outlined"
                            multiline
                            value={notes.power_notes ? notes.power_notes : ""}
                            disabled={true}
                            sx={{
                                width: "50%"
                            }}
                        />
                    </Grid>
                    {
                        notes.tasks &&
                        notes.tasks.map((value, idx) =>
                            <Grid key={idx + 1} item xs={4}>
                                <Stack spacing={2}>
                                    <TextField
                                        multiline
                                        id={`${idx}-Task Completed`}
                                        label={"Task Completed"}
                                        style={{
                                            width: "50%",
                                            alignSelf: 'center'
                                        }}
                                        value={value.task}
                                        disabled={true}
                                    />
                                    <Stack direction="row" spacing={2} sx={{
                                        alignSelf: 'center'
                                    }}>
                                        <TextField id={`${idx}-Time Spent`} label="Time Spent" variant="outlined" type={'number'}
                                            sx={{
                                                width: "85%",
                                            }}
                                            value={value.time_spent}
                                            disabled={true}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>)
                    }
                    {
                        notes.issues &&
                        notes.issues.map((value, idx) =>
                            <Grid key={idx} item xs={4}>
                                <Stack spacing={2} direction="row" justifyContent="center"
                                    alignItems="center">
                                    <TextField
                                        multiline
                                        disabled={true}
                                        id={`${idx}-issue`}
                                        label={"Issues"}
                                        value={value}
                                    />
                                </Stack>
                            </Grid>)
                    }
                    <Grid item xs={4}>
                        <TextField id="special_tools" name='special_tools' label="Special Tools Needed" variant="outlined"
                            multiline
                            value={notes.special_tools ? notes.special_tools : ""}
                            sx={{
                                width: "50%"
                            }}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="work_performed" name='work_performed' label="Work Performed" variant="outlined"
                            multiline
                            value={notes.work_performed}
                            onChange={handleChange}
                            sx={{
                                width: "50%",
                            }}
                        // InputProps={{
                        //     rows: 5
                        // }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outcomes_issues" name='outcomes_issues' label="Outcomes/Issues" variant="outlined"
                            multiline
                            value={notes.outcomes_issues}
                            onChange={handleChange}
                            sx={{
                                width: "50%",
                            }}
                        // InputProps={{
                        //     rows: 5
                        // }}
                        />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    {
                        pictures_keys.map((dict, key) => (
                            <Grid item xs={12} key={key}>
                                {
                                    (notes[dict.aws] && notes[dict.aws].length) ?
                                        <React.Fragment>
                                            <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                                color: 'black',
                                                marginLeft: "2%"
                                            }}>
                                                {`${dict.name}`}
                                            </Typography>
                                            <ImageList cols={3} gap={8}>
                                                {notes[dict.aws].map((item, key) => (
                                                    <ImageListItem key={key}>
                                                        <img
                                                            src={`${item.obj}`}
                                                            alt={`${dict.name}`}
                                                            loading="lazy"
                                                            style={{ objectFit: "contain" }}
                                                        />
                                                        <TextField id="caption" label="Caption" variant="outlined" placeholder='text'
                                                            multiline
                                                            value={caption[item.obj] ? caption[item.obj].value : ''}
                                                            sx={{
                                                                mt: '2%'
                                                            }}
                                                            onChange={(e) => {
                                                                if (e.target.value) {
                                                                    setCaption(prev => ({
                                                                        ...prev,
                                                                        [item.obj]: {
                                                                            value: e.target.value,
                                                                            buffer: item.buffer,
                                                                            key: item.key
                                                                        }
                                                                    }))
                                                                } else {
                                                                    setCaption(prev => ({
                                                                        ...prev,
                                                                        [item.obj]: {
                                                                            value: e.target.value,
                                                                        }
                                                                    }))
                                                                }
                                                            }}
                                                        />
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        </React.Fragment>
                                        : <React.Fragment>
                                            {
                                                (notes[dict.key] && notes[dict.key].length) ?
                                                    <Stack spacing={2}>
                                                        <TextField
                                                            multiline
                                                            placeholder={` Selected: ${notes[dict.key].length}`}
                                                            label={`Selected ${dict.name}`}
                                                            inputProps={
                                                                { readOnly: true }
                                                            }
                                                            style={{
                                                                width: "50%",
                                                                alignSelf: 'center'
                                                            }}
                                                            color="secondary"
                                                            variant="filled"
                                                            focused
                                                        />
                                                        <Button variant="outlined" component="label" onClick={() => downloadImageAWS(`${dict.key}`)} startIcon={loadingPhotos[dict.key] ? <CircularProgress color='grey' /> : <CloudDownloadIcon />} style={{
                                                            width: "50%",
                                                            alignSelf: 'center'
                                                        }}
                                                            disabled={loadingPhotos[dict.key] ? true : false}
                                                        >
                                                            Load {`${dict.name}`}
                                                        </Button>
                                                    </Stack> : <React.Fragment></React.Fragment>
                                            }
                                        </React.Fragment>
                                }
                            </Grid>
                        ))
                    }

                    {/* <Grid item xs={12}> */}
                    <Grid item xs={6}>
                        <Button variant="outlined" component="label" style={{
                            width: "50%",
                            alignSelf: 'center'
                        }} onClick={loadFile}
                            startIcon={<NorthEastIcon />}
                        >
                            Load PDF file
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="outlined" component="label" color='secondary' style={{
                            width: "50%",
                            alignSelf: 'center'
                        }} onClick={saveFile}
                            startIcon={<UploadIcon />}
                        >
                            Save PDF
                        </Button>
                    </Grid>
                    {/* </Grid> */}

                </Grid>
            }
        </React.Fragment >
    )
}