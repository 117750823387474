
import Add from "./Add";
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import CONSTANTS from '../../helpers/constants';
import DialogContent from '@mui/material/DialogContent';
import React, { useEffect, useState, useCallback } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

export default function ViewEquipments(props) {
    const [limit, setLimit] = useState(10)
    const [results, setResults] = useState(null)
    const [editData, setEditData] = useState({})
    const [loading, setLoading] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    // const [searchLimit, setSearchLimit] = useState(10)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [openAddModal, setOpenAddModal] = useState(false)
    // const [loadMoreSearch, setLoadMoreSearch] = useState(false)
    const [total, setTotal] = useState(0)


    let columns = [
        {
            field: 'equipment_id',
            headerName: 'Equipment Id',
            flex: 1
        },
        {
            field: 'most_recent',
            headerName: 'Tester',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value.tester ? value.tester : '-'}`,
        },
        {
            field: 'operational',
            headerName: 'Operational',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value}`,
        },
        {
            field: 'assigned',
            headerName: 'Assigned',
            flex: 1,
            valueGetter: ({ value }) =>
                `${value}`,
        },
        {
            field: 'water_test_date',
            headerName: 'Date (Water Test)',
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toDateString('YYYY-MM-DD') : '-'}`,
            flex: 1
        },
        {
            field: 'electronic_test_date',
            headerName: 'Date (Electronic Test)',
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toDateString('YYYY-MM-DD') : '-'}`,
            flex: 1
        },
        {
            field: 'time_inserted',
            headerName: 'Date (Inserted)',
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toDateString('YYYY-MM-DD') : '-'}`,
            flex: 1
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            disableClickEventBubbling: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1}>
                        <EditIcon id={params.row.id} color='warning' sx={{ cursor: 'pointer' }} onClick={() => {
                            changeAndSetEditData(params.row)
                            setOpenEditModal(true)
                        }} />
                    </Stack>
                )
            },
        },
    ]
    function changeAndSetEditData(row) {
        let new_data = {}
        new_data.operational = row.operational
        new_data.water_test_date = new Date(row.water_test_date)
        new_data.electronic_test_date = new Date(row.electronic_test_date)
        new_data.test = row.most_recent.test_type
        new_data.tester = row.most_recent.tester
        new_data.equipment_id = row.equipment_id
        new_data.type = row.most_recent.type
        new_data.status = row.most_recent.status
        setEditData(new_data)
    }
    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: 'Equipments'
                }} />
            </GridToolbarContainer>
        );
    }
    function handleDialogClose() {
        setOpenEditModal(!openEditModal)
    }

    function handleDialogCloseAdd() {
        setOpenAddModal(!openAddModal)
    }

    const getEquipments = useCallback(async (token, fromStart) => {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        let limit_to_put = fromStart ? 10 : limit
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_EQUIPMENT_VIEW_API_URL.replace(":limit", limit_to_put)}`, { headers })
            .then((response) => {
                let temp = limit
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value) => {
                    value.id = value._id
                    value.water_test_date = value.most_recent.water_test_date
                    value.electronic_test_date = value.most_recent.electronic_test_date
                    return value
                })
                if (fromStart) {
                    setResults(data)
                    setLimit(temp += 10)
                } else {
                    if (results && results.length) {
                        setResults(results => results.concat(data))
                    } else {
                        setResults(data)
                    }
                    setLimit(temp += 10)
                }
                setLoadMore(response.data.hasMore)
                setTotal(response.data.total_items)
            })
    }, [limit, results])

    function CustomFooterTotalComponent(props) {
        return (
            <Box m={1} p={1}><b>Displaying {results.length} of {total ? total : 0}</b></Box>
        );
    }
    useEffect(() => {
        if (props.token && !results) {
            getEquipments(props.token, true)
        }
    }, [props.token, results, getEquipments])

    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                <Grid item xs={12} mt={2}>
                    <Button variant="outlined" onClick={() => {
                        setOpenAddModal(true)
                    }}>Add New</Button>
                </Grid>
            </Grid>
            {
                results &&
                <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                    <Grid item xs={12} mt={2}>
                        <DataGrid
                            rows={results}
                            columns={columns}
                            autoHeight={true}
                            components={{
                                Toolbar: CustomToolbar,
                                Footer: CustomFooterTotalComponent
                            }}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            }
            {
                loadMore &&
                <Box m={1} p={1}>
                    <Button variant="outlined" onClick={() => {
                        getEquipments(props.token, false)
                    }}>Load More</Button>
                </Box>
            }
            {!loading &&
                <Dialog open={openEditModal} onClose={handleDialogClose} maxWidth="lg">
                    <DialogContent>
                        <Add token={props.token} title={'Edit Modal'} data={editData} getEquipments={getEquipments} handleDialogClose={handleDialogClose} isEdit={true} />
                    </DialogContent>
                </Dialog>
            }
            {!loading &&
                <Dialog open={openAddModal} onClose={handleDialogCloseAdd} maxWidth="xl">
                    <DialogContent>
                        <Add token={props.token} title={'Add Modal'} data={{
                            type: '',
                            test: 'WATER',
                            tester: 'CHELSEA',
                            water_test_date: new Date(),
                            electronic_test_date: new Date()
                        }} getEquipments={getEquipments} handleDialogClose={handleDialogCloseAdd} isEdit={false} />
                    </DialogContent>
                </Dialog>
            }
        </React.Fragment>
    )
}