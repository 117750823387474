import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import CONSTANTS from '../../helpers/constants';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

export default function ViewReports(props) {
    const [loadMoreSearch, setLoadMoreSearch] = useState(false)
    const [loadMore, setLoadMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const [results, setResults] = useState(null)
    const [search, setSearch] = useState({
        sites: "",
    })
    const [limit, setLimit] = useState(10)
    const [searchLimit, setSearchLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const navigate = useNavigate();


    let columns = [
        {
            field: 'site',
            headerName: 'Site',
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
            flex: 1
        },
        {
            field: 'date_added',
            headerName: 'Date (Added)',
            valueGetter: ({ value }) =>
                `${value ? new Date(value).toLocaleString('en-US', { timeZone: 'America/Regina' }) : '-'}`,
            flex: 1
        },
        {
            field: "user_name",
            headerName: 'Added By',
            valueGetter: ({ value }) =>
                `${value ? value : '-'}`,
            flex: 1
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            disableClickEventBubbling: true,
            flex: 1,
            renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={5}>
                        <DownloadIcon id={params.row.id} color='primary' sx={{ cursor: 'pointer' }} onClick={() => {
                            getPdfUrl(params.row)
                        }} />

                        <DeleteForeverIcon id={params.row.id} color='error' sx={{ cursor: 'pointer' }} onClick={() => {
                            deletePdfUrl(params.row)
                        }} />
                        {
                            Object.keys(params.row).length > 8 ?
                            <AssessmentIcon id={params.row.id} color='secondary' sx={{ cursor: 'pointer' }} onClick={() => {
                                openReport(params.row)
                            }} /> : <></>
                        }
                    </Stack>
                )
            },
        },
    ]
    async function getPdfUrl(row) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.REPORT_PDF_API}`, row, { headers })
            .then((result) => {
                setLoading(false)
                window.open(result.data.data)
            })
    }

    async function deletePdfUrl(row) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.REPORT_PDF_DELETE_API}`, row, { headers })
            .then(() => {
                setLoading(true)
                getReports(props.token, true)
                setLoading(false)
            })
    }

    function openReport(row) {
        navigate(
            `${CONSTANTS.PAGE_FIELD_REPORTS_ADD.replace(":notesId", row._id)}?site=${row.site}`
        );
    }

    async function getReports(token, fromStart) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        let limit_to_put = fromStart ? 10 : limit
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.REPORT_SEARCH_API.replace(":limit", limit_to_put)}`, {}, { headers })
            .then((response) => {
                let temp = limit
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value) => {
                    value.id = value._id
                    return value
                })
                if (fromStart) {
                    setResults(data)
                    setLimit(temp += 10)
                } else {
                    if (results && results.length) {
                        setResults(results => results.concat(data))
                    } else {
                        setResults(data)
                    }
                    setLimit(temp += 10)
                }
                setLoadMore(response.data.hasMore)
                setTotal(response.data.total_items)
            })
    }

    async function getReportsSearch(token) {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        let limit_to_put = searchLimit
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.REPORT_SEARCH_API.replace(":limit", limit_to_put)}`,
            search, { headers })
            .then((response) => {
                let temp = searchLimit
                setLoading(false)
                let data = response.data ? response.data.data : []
                data = data.map((value) => {
                    value.id = value._id
                    return value
                })
                if (searchLimit === 10) {
                    setResults(data)
                } else {
                    if (results && results.length) {
                        setResults(results => results.concat(data))
                    } else {
                        setResults(data)
                    }
                    setSearchLimit(temp += 10)
                }
                setLoadMoreSearch(response.data.hasMore)
                setTotal(response.data.total_items)
            })
    }


    const handleChange = (event) => {
        event.preventDefault()
        const { target: { value } } = event;
        const { target: { name } } = event;
        setSearch(prev => ({
            ...prev,
            [name]: value
        }))
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: 'Reports'
                }} />
            </GridToolbarContainer>
        );
    }

    function CustomFooterTotalComponent(props) {
        return (
            <Box m={1} p={1}><b>Displaying {results.length} of {total ? total : 0}</b></Box>
        );
    }

    useEffect(() => {
        if (props.token && !results) {
            setLoading(true)
            getReports(props.token, true)
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [props.token, results])

    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Box component="span" sx={{ p: 2, borderColor: '#EBF5FB', border: '1px dashed', m: 1 }}>
                    <Grid container rowSpacing={2} columnSpacing={2} mt={2} mb={1}>
                        <Grid item xs={4}>
                            <TextField id="checklist-search-site" name='sites' label="Site(s)" variant="outlined"
                                value={search.sites}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        getReportsSearch(props.token)
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={4} mt={2}>
                            <Button variant="outlined" color="primary" startIcon={<SearchIcon />}
                                onClick={() => {
                                    getReportsSearch(props.token)
                                }}>Search</Button>
                        </Grid>
                        <Grid item xs={4} mt={2}>
                            <Button variant="outlined" color="warning"
                                onClick={() => {
                                    setSearch({
                                        sites: '',
                                    })
                                    setSearchLimit(10)
                                    setLoadMoreSearch(false)
                                    getReports(props.token, true)
                                }} startIcon={<ClearIcon />}>Clear</Button>
                        </Grid>
                    </Grid>
                </Box>
            }
            {!loading && <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                <Grid item xs={12} mt={2}>
                    {
                        results ?
                            <DataGrid
                                rows={results}
                                columns={columns}
                                autoHeight={true}
                                components={{
                                    Toolbar: CustomToolbar,
                                    Footer: CustomFooterTotalComponent
                                }}
                                loading={loading}
                            /> : <React.Fragment></React.Fragment >
                    }
                </Grid>
            </Grid>}
            <Box m={1} p={1}>
                {loadMore && <Button variant="outlined" onClick={() => {
                    getReports(props.token, false)
                }}>Load More</Button>}
            </Box>
            <Box m={1} p={1}>
                {loadMoreSearch && <Button variant="outlined" onClick={() => {
                    getReportsSearch(props.token)
                }}>Load More</Button>}
            </Box>
        </React.Fragment>
    )
}