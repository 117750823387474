import axios from 'axios';
import Title from './Title';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import CONSTANTS from '../../helpers/constants';
import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';


export default function Items(props) {
    const [data, setData] = useState([])
    const [limit, setLimit] = useState(5)
    const [loading, setLoading] = useState(true)
    let temp = 0
    const headers = {
        'Authorization': 'Bearer ' + props.token,
    }
    async function getTopInventoryItems() {
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.TOP_INVENTORY_API_URL}`,
            {
                headers,
            })
            .then(response => setData(response.data.items))
    }
    async function getLowStockInventoryItems() {
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.LOW_STOCK_INVENTORY_API_URL}`.replace(':limit', limit),
            {
                headers: headers,
            })
            .then(response => {
                temp = limit
                setData(item => item.concat(response.data.items))
                setLimit(temp += 5)

            })
    }
    function preventDefault(event) {
        event.preventDefault();
        setLoading(true)
        getLowStockInventoryItems(props.token)
        setLoading(false)
    }


    useEffect(() => {
        if (props.token) {
            if (props.title === 'Important Inventory Items') {
                setLoading(true)
                getTopInventoryItems();
                setLoading(false)
            } else if (props.title === 'Low Stock Inventory Items') {
                setLoading(true)
                getLowStockInventoryItems()
                setLoading(false)
            }
        }
        // eslint-disable-next-line
    }, [props.token, props.title])
    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading && <CircularProgress />}
            {data.length && <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date Modified</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell align="right">Stock</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, idx) => (
                        <TableRow key={`${row.id}${props.title}${idx}`}>
                            <TableCell>{new Date(row.last_modified_time).toLocaleString('en-US',
                                { timeZone: 'America/Regina' })}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell style={{
                                whiteSpace: "normal",
                                wordBreak: "break-word"
                            }}>{row.description}</TableCell>
                            <TableCell>{row.sku}</TableCell>
                            <TableCell align="right">{`${row.stock}`}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>}
            {props.title === "Low Stock Inventory Items" &&
                <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
                    See more low stock inventory items
                </Link>}
        </React.Fragment>
    );
}