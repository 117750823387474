import axios from "axios";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import Title from "../Dashboard/Title";
import Stack from "@mui/material/Stack";
import EditNotes from "./../Notes/Edit";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import NoteDetails from "./../Notes/Details";
import { useNavigate } from "react-router-dom";
import CONSTANTS from "../../helpers/constants";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import PreviewIcon from "@mui/icons-material/Preview";
import DialogContent from "@mui/material/DialogContent";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useEffect, useState, useCallback } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

export default function ViewNotes(props) {
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [loadMoreSearch, setLoadMoreSearch] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [search, setSearch] = useState({
    site: "",
    issues: "",
    date_from: "",
    date_to: "",
  });
  const [limit, setLimit] = useState(10);
  const [editData, setEditData] = useState({});
  const [searchLimit, setSearchLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  let columns = [
    {
      field: "site",
      headerName: "Site",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date (Field Trip)",
      valueGetter: ({ value }) =>
        `${value ? new Date(value).toISOString().split('T')[0] : "-"}`,
      flex: 1,
    },
    {
      field: "hours",
      headerName: "Hours Worked",
      flex: 1,
      valueGetter: ({ value }) => `${value ? value : "-"}`,
    },
    {
      field: "type",
      headerName: "Trip For",
      flex: 1,
      valueGetter: ({ value }) => `${value ? value : "-"}`,
    },
    {
      field: "reason",
      headerName: "Trip Reason",
      flex: 1,
      valueGetter: ({ value }) => `${value ? value : "-"}`,
    },
    {
      field: "scope",
      headerName: "Scope of Work",
      flex: 1,
      valueGetter: ({ value }) => `${value ? value : "-"}`,
    },
    {
      field: "field_persons",
      headerName: "Field Crew",
      sortable: false,
      disableClickEventBubbling: true,
      flex: 2,
      renderCell: (params) => {
        return (
          <Stack direction="row" justifyContent={"center"}>
            {params.row.field_persons.map((item, id) => (
              <Chip key={id} label={item} />
            ))}
          </Stack>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      disableClickEventBubbling: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1}>
            <PreviewIcon
              id={params.row.id}
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setEditData(params.row);
                setOpenEditModal(true);
              }}
            />
            <EditIcon
              id={params.row.id}
              color="warning"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setEditData(params.row);
                setOpenDetailModal(true);
              }}
            />
            <AssessmentIcon
              id={params.row.id}
              color="secondary"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(
                  CONSTANTS.PAGE_FIELD_REPORTS_ADD.replace(
                    ":notesId",
                    params.row._id
                  )
                );
              }}
            />
          </Stack>
        );
      },
    },

    {
      field: "distance_travelled",
    },
    {
      field: "date_added",
    },
    {
      field: "time_arrival",
    },
    {
      field: "time_departure",
    },
    {
      field: "_id",
    },
  ];

  function CustomFooterTotalComponent(props) {
    return (
      <Box m={1} p={1}>
        <b>
          Displaying {results.length} of {total ? total : 0}
        </b>
      </Box>
    );
  }

  const getNotes = useCallback(
    async (token, fromStart) => {
      setLoading(true);
      const headers = {
        authorization: "Bearer " + token,
      };
      let limit_to_put = fromStart ? 10 : limit;
      await axios
        .get(
          `${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_GET_API.replace(
            ":limit",
            limit_to_put
          )}`,
          { headers }
        )
        .then((response) => {
          let temp = limit + 10;
          setLoading(false);
          let data = response.data ? response.data.data : [];
          data = data.map((value) => {
            value.id = value._id;
            return value;
          });
          if (fromStart) {
            setResults(data);
            setLimit(temp);
          } else {
            if (results && results.length) {
              setResults((results) => results.concat(data));
            } else {
              setResults(data);
            }
            setLimit(temp);
          }
          setLoadMore(response.data.hasMore);
          setTotal(response.data.total_items);
        });
    },
    [limit, results]
  );

  async function getNotesSearch(token) {
    setLoading(true);
    const headers = {
      authorization: "Bearer " + token,
    };
    let limit_to_put = searchLimit;
    await axios
      .post(
        `${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_SEARCH_API.replace(
          ":limit",
          limit_to_put
        )}`,
        search,
        { headers }
      )
      .then((response) => {
        let temp = searchLimit;
        setLoading(false);
        let data = response.data ? response.data.data : [];
        data = data.map((value) => {
          value.id = value._id;
          return value;
        });
        if (searchLimit === 10) {
          setResults(data);
        } else {
          if (results && results.length) {
            setResults((results) => results.concat(data));
          } else {
            setResults(data);
          }
          setSearchLimit((temp += 10));
        }
        setLoadMoreSearch(response.data.hasMore);
        setTotal(response.data.total_items);
      });
  }

  async function getSites(token) {
    const headers = {
      authorization: "Bearer " + token,
    };
    await axios
      .get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_ALL_API_URL}`, {
        headers,
      })
      .then((response) => {
        let data = response.data;
        data.sort((a, b) => {
          if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
          if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
          return 0;
        });
        setSiteData(data);
      });
  }

  const handleChange = (event) => {
    event.preventDefault();
    const {
      target: { value },
    } = event;
    const {
      target: { name },
    } = event;
    search[name] = value;
    setSearch((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            allColumns: true,
            fileName: "Notes",
          }}
        />
      </GridToolbarContainer>
    );
  }

  function handleDialogClose() {
    setOpenEditModal(!openEditModal);
  }

  function handleDetailDialogClose() {
    setOpenDetailModal(!openDetailModal);
  }

  useEffect(() => {
    if (props.token && !results) {
      getNotes(props.token, true);
      setLoading(true);
      getSites(props.token);
      setLoading(false);
    }
  }, [props.token, results, getNotes]);
  return (
    <React.Fragment>
      <Title>{props.title}</Title>
      {loading && (
        <Box m={5} p={5}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Box
          component="span"
          sx={{ p: 2, borderColor: "#EBF5FB", border: "1px dashed", m: 1 }}
        >
          <Grid container rowSpacing={2} columnSpacing={2} mt={1} mb={1}>
            <Grid item xs={2}>
              <TextField
                id="quantifier-search-site"
                name="site"
                label="Site"
                variant="outlined"
                value={search.site}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (search.site || search.issues || search.date_from || search.date_to) {
                      setLoadMore(false)
                      getNotesSearch(props.token);
                    }
                    else {
                      setSearchLimit(10);
                      setLoadMoreSearch(false);
                      getNotes(props.token, true);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                id="quantifier-search-issues"
                name="issues"
                label="Issues"
                variant="outlined"
                value={search.issues}
                onChange={handleChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (search.site || search.issues || search.date_from || search.date_to) {
                      setLoadMore(false)
                      getNotesSearch(props.token);
                    }
                    else {
                      setSearchLimit(10);
                      setLoadMoreSearch(false);
                      getNotes(props.token, true);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Range - From"
                  value={search.date_from}
                  onChange={(newValue) => {
                    setSearch((prev) => ({
                      ...prev,
                      date_from: newValue
                        ? new Date(newValue.startOf("day"))
                        : "",
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Range - To"
                  value={search.date_to}
                  onChange={(newValue) => {
                    setSearch((prev) => ({
                      ...prev,
                      date_to: newValue ? new Date(newValue.endOf("day")) : "",
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1.5} mt={2}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<SearchIcon />}
                onClick={() => {
                  if (search.site || search.issues || search.date_from || search.date_to) {
                    setLoadMore(false)
                    getNotesSearch(props.token);
                  }
                  else {
                    setSearchLimit(10);
                    setLoadMoreSearch(false);
                    getNotes(props.token, true);
                  }
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={1} mt={2}>
              <Button
                variant="outlined"
                color="warning"
                onClick={() => {
                  setSearch({
                    issues: "",
                    site: "",
                    date_to: "",
                    date_from: "",
                  });
                  setSearchLimit(10);
                  setLoadMoreSearch(false);
                  getNotes(props.token, true);
                }}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      {!loading && (
        <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
          <Grid item xs={12} mt={2}>
            {results ? (
              <DataGrid
                rows={results}
                columns={columns}
                autoHeight={true}
                components={{
                  Toolbar: CustomToolbar,
                  Footer: CustomFooterTotalComponent,
                }}
                columnVisibilityModel={{
                  // Hide these columns
                  distance_travelled: false,
                  date_added: false,
                  time_arrival: false,
                  time_departure: false,
                  _id: false,
                }}
                loading={loading}
              />
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Grid>
        </Grid>
      )}
      <Box m={1} p={1}>
        {loadMore && (
          <Button
            variant="outlined"
            onClick={() => {
              getNotes(props.token, false);
            }}
          >
            Load More
          </Button>
        )}
      </Box>
      <Box m={1} p={1}>
        {loadMoreSearch && (
          <Button
            variant="outlined"
            onClick={() => {
              getNotesSearch(props.token);
            }}
          >
            Load More
          </Button>
        )}
      </Box>
      {!loading && (
        <Dialog
          open={openEditModal}
          onClose={handleDialogClose}
          maxWidth={false}
        >
          <DialogContent>
            <EditNotes
              token={props.token}
              data={editData}
              title={`Edit Notes`}
              handleDialogClose={handleDialogClose}
              getNotes={getNotes}
            ></EditNotes>
          </DialogContent>
        </Dialog>
      )}
      {!loading && (
        <Dialog
          open={openDetailModal}
          onClose={handleDetailDialogClose}
          maxWidth="xl"
        >
          <DialogContent>
            <NoteDetails
              token={props.token}
              data={editData}
              title={`Note Details`}
              handleDialogClose={handleDetailDialogClose}
              getNotes={getNotes}
              siteData={siteData}
            ></NoteDetails>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}
