
import axios from 'axios';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CONSTANTS from '../../helpers/constants';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import LocalStorage from "../Login/local-storage";
import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import PublishIcon from '@mui/icons-material/Publish';
// import UpgradeIcon from '@mui/icons-material/Upgrade';
import OutlinedInput from '@mui/material/OutlinedInput';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function Kpi(props) {
    let ls = new LocalStorage()
    const [rgData, setRgData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [payloadDataRG, setPayloadDataRG] = useState({
        from: null,
        until: null
    })
    const [calibrationData, setCalibrationData] = useState(null)
    const [payloadDataCalibration, setPayloadDataCalibration] = useState({
        from: null,
        until: null
    })
    const [value, setValue] = useState(0);
    const [search, setSearch] = useState('')
    const [provenanceData, setProvenanceData] = useState({})
    const [expanded, setExpanded] = useState(false);
    const [siteData, setSiteData] = useState();
    const [payloadFieldTest, setPayloadFieldTest] = useState({
        quantifiers: [],
        site: "Annaheim"
    })
    // const [sensorCurrentState, setSensorCurrentState] = useState({
    //     state: provenanceData.sensor_state
    // })
    const [fieldTestData, setFieldTestData] = useState([])
    const [quantifiers, setQuantifiers] = useState()

    let columns = [
        {
            field: 'sensor_id',
            headerName: 'Sensor Id',
            flex: 1,
        },
        {
            field: 'tested',
            headerName: 'Times Tested',
            flex: 1,
        },
        {
            field: 'passed',
            headerName: 'Times Passed',
            flex: 1,
        },
        {
            field: 'failed',
            headerName: 'Times Failed',
            flex: 1,
        }
    ]

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function handleSubmitCalibration() {
        setLoading(true)
        let url = `${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.KPI_GET_API.replace(":test", CONSTANTS.CALIBRATION)}`
        if (payloadDataCalibration.from) {
            url = url + `/${payloadDataCalibration.from.getTime()}`
            if (payloadDataCalibration.until) {
                url = url + `/${payloadDataCalibration.until.getTime()}`
            }
        } else {
            if (payloadDataCalibration.until) {
                url = url + `/0/${payloadDataCalibration.until.getTime()}`
            }
        }
        getKPIData(props.token, CONSTANTS.CALIBRATION, url)
        setLoading(false)
    }

    function handleSubmitRG() {
        setLoading(true)
        let url = `${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.KPI_GET_API.replace(":test", CONSTANTS.RED_GREEN)}`
        if (payloadDataRG.from) {
            url = url + `/${payloadDataRG.from.getTime()}`
            if (payloadDataRG.until) {
                url = url + `/${payloadDataRG.until.getTime()}`
            }
        } else {
            if (payloadDataRG.until) {
                url = url + `/0/${payloadDataRG.until.getTime()}`
            }
        }
        getKPIData(props.token, CONSTANTS.RED_GREEN, url)
        setLoading(false)
    }

    async function submitPayload() {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.KPI_FIELD_TEST_INSERT_API}`, payloadFieldTest, { headers }).then(response => {
            setFieldTestData(response.data)
            setLoading(false)
        })
    }

    async function getKPIData(token, type, url) {
        const headers = {
            'authorization': 'Bearer ' + token,
        }
        if (type === CONSTANTS.CALIBRATION) {
            await axios.get(url, { headers }).then(response => {
                setCalibrationData(response.data)
            })
        } else if (type === CONSTANTS.RED_GREEN) {
            await axios.get(url, { headers }).then(response => {
                setRgData(response.data)
            })
        } else {
            await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.KPI_GET_API.replace(":test", CONSTANTS.CALIBRATION)}`, { headers }).then(response => {
                setCalibrationData(response.data)
            })
            await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.KPI_GET_API.replace(":test", CONSTANTS.RED_GREEN)}`, { headers }).then(response => {
                setRgData(response.data)
            })
        }
    }

    async function getProvenanceData() {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.KPI_PROVENANCE_API.replace(":id", search)}`, { headers }).then(response => {
            setProvenanceData(response.data)
            // setSensorCurrentState({
            //     state: response.data.sensor_state
            // })
            setLoading(false)
        })
    }

    // async function setSensorState() {
    //     setLoading(true)
    //     const headers = {
    //         'authorization': 'Bearer ' + props.token,
    //     }
    //     let sensor_search = ''
    //     if (provenanceData && provenanceData.sensor_id) {
    //         sensor_search = provenanceData.sensor_id
    //     } else {
    //         sensor_search = provenanceData.uuid
    //     }
    //     await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.UPDATE_SENSOR_STATE.replace(":id", sensor_search)}`, sensorCurrentState, { headers }).then(() => {
    //         getProvenanceData()
    //     })
    // }

    async function getSites() {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.SITES_ALL_API_URL}`, { headers })
            .then((response) => {
                let data = response.data
                data.sort((a, b) => {
                    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) return -1;
                    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
                    return 0
                })
                setSiteData(data)
            })
    }

    async function getMetaData(site) {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.get(`${CONSTANTS.DEFAULT_API_URL}/quantifier-metadata/${site}`,
            {
                method: 'GET',
                headers: headers
            })
            .then(response => {
                response = response.data.map(item => {
                    item["quantifier"] = item["quantifier_name"]
                    delete item.quantifier_name
                    delete item._id
                    return item
                })
                response = response.sort((a, b) => (a.quantifier > b.quantifier) ? 1 : ((b.quantifier > a.quantifier) ? -1 : 0))
                setQuantifiers(response)
            });
    }

    const handleChangePanel = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function CustomFooterTotalComponent(props) {
        return (
            <Box m={1} p={1}><b>{fieldTestData.length} Sensors Got Updated</b></Box>
        );
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport csvOptions={{
                    allColumns: true,
                    fileName: `field_test_${payloadFieldTest.site}_${new Date().toDateString("YYYY-MM-DD")}`
                }} />
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        if (props.token && !calibrationData && !rgData) {
            setLoading(true)
            getKPIData(props.token)
            if (!siteData)
                getSites()
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [props.token, calibrationData, rgData, siteData])

    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            <Box sx={{ width: '100%' }}>
                <Tabs value={value} onChange={handleChange}>
                    <Tab value={0} label="Sensor KPI" />
                    <Tab value={1} label="Sensor Provenance" />
                    {
                        // zohaib, mamet, chelsea
                        (["2857783000004558002", "2857783000000820247", "2857783000000735170"].includes(ls.getLocalStorageItemZoho().user_id)) &&
                        <Tab value={2} label="Field Test" />
                    }
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Grid container rowSpacing={5} alignContent="center" justify="center">
                        <Grid item xs={6}>
                            {/* <hr /> */}
                            <Title>Calibration</Title>
                            {
                                (calibrationData) &&
                                <React.Fragment>
                                    {/* <hr /> */}
                                    <Grid container spacing={1} >
                                        <Grid item xs={4.5} mt={2} mb={2}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="From"
                                                    placeholder='From'
                                                    value={payloadDataCalibration.from}
                                                    onChange={(newValue) => {
                                                        if (newValue) {
                                                            setPayloadDataCalibration(prev => ({
                                                                ...prev,
                                                                from: new Date(newValue.startOf('day')),
                                                            }))
                                                        } else {
                                                            setPayloadDataCalibration(prev => ({
                                                                ...prev,
                                                                from: null,
                                                            }))
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={4.5} mt={2} mb={2}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="Until"
                                                    placeholder='Until'
                                                    value={payloadDataCalibration.until}
                                                    onChange={(newValue) => {
                                                        if (newValue) {
                                                            setPayloadDataCalibration(prev => ({
                                                                ...prev,
                                                                until: new Date(newValue.endOf('day')),
                                                            }))
                                                        } else {
                                                            setPayloadDataCalibration(prev => ({
                                                                ...prev,
                                                                until: null,
                                                            }))
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={2.5} mt={2} mb={2} alignSelf={'center'}>
                                            <Button variant="contained" onClick={handleSubmitCalibration} startIcon={<FilterAltIcon />}>Filter</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Overall Total</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.overall_total}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Overall Passed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.overall_passed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Overall Passing Percentage</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.overall_pass_rate}
                                        </Grid>
                                        <Divider orientation="horizontal" flexItem style={{ width: "100%", marginRight: "2%", marginTop: "2%" }} />
                                        <Grid item xs={6}>
                                            <b>Sensors Calibrated Once</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_once}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Passed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_once_passed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Passing Percentrage</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_once_pass_rate}
                                        </Grid>
                                        <Divider orientation="horizontal" flexItem style={{ width: "100%", marginRight: "2%", marginTop: "2%" }} />
                                        <Grid item xs={6}>
                                            <b>Sensors Calibrated Twice</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_twice}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Passed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_twice_passed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Passing Percentrage</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_twice_pass_rate}
                                        </Grid>
                                        <Divider orientation="horizontal" flexItem style={{ width: "100%", marginRight: "2%", marginTop: "2%" }} />
                                        <Grid item xs={6}>
                                            <b>Sensors Calibrated More Than Twice</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_alot}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Passed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_alot_passed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Pass Percentrage</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {calibrationData.calibrated_alot_pass_rate}
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={5.9}>
                            {/* <hr /> */}
                            <Title>Red Green Test</Title>
                            {
                                (rgData) &&
                                <React.Fragment>
                                    {/* <hr /> */}
                                    <Grid container spacing={1}>
                                        <Grid item xs={5} mt={2} mb={2}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="From"
                                                    placeholder='From'
                                                    value={payloadDataRG.from}
                                                    onChange={(newValue) => {
                                                        if (newValue) {
                                                            setPayloadDataRG(prev => ({
                                                                ...prev,
                                                                from: new Date(newValue.startOf('day')),
                                                            }))
                                                        } else {
                                                            setPayloadDataRG(prev => ({
                                                                ...prev,
                                                                from: null,
                                                            }))
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={5} mt={2} mb={2}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="Until"
                                                    placeholder='Until'
                                                    value={payloadDataRG.until}
                                                    onChange={(newValue) => {
                                                        if (newValue) {
                                                            setPayloadDataRG(prev => ({
                                                                ...prev,
                                                                until: new Date(newValue.endOf('day')),
                                                            }))
                                                        } else {
                                                            setPayloadDataRG(prev => ({
                                                                ...prev,
                                                                until: null,
                                                            }))
                                                        }
                                                    }}
                                                    renderInput={(params) => <TextField {...params} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={2} mt={2} mb={2} alignSelf={'center'}>
                                            <Button variant="contained" onClick={handleSubmitRG} startIcon={<FilterAltIcon />}>Filter</Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Pre Production Tested</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.pre_production_passed + rgData.pre_production_failed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Pre Production Passed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.pre_production_passed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Pre Production Failed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.pre_production_failed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Pre Production Passing Percentage</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.pre_production_rate}
                                        </Grid>
                                        <Divider orientation="horizontal" flexItem style={{ width: "100%", margin: "1%" }} />
                                        <Grid item xs={6}>
                                            <b>Post Production Tested</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.post_production_passed + rgData.post_production_failed}
                                        </Grid>

                                        <Grid item xs={6}>
                                            <b>Post Production Passed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.post_production_passed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Post Production Failed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.post_production_failed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Post Production Passing Percentage</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.post_production_rate}
                                        </Grid>
                                        <Divider orientation="horizontal" flexItem style={{ width: "100%", margin: "1%" }} />
                                        <Grid item xs={6}>
                                            <b>Field Test Tested</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.field_passed + rgData.field_failed}
                                        </Grid>

                                        <Grid item xs={6}>
                                            <b>Field Test Passed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.field_passed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Field Test Failed</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.field_failed}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <b>Field Test Passing Percentage</b>
                                        </Grid>
                                        <Grid item xs={4}>
                                            {rgData.field_rate}
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            }
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} mt={2} mb={2}>
                            <Title>{'Sensor Provenance'}</Title>
                        </Grid>
                        <Grid item xs={8} mt={2} mb={2}>
                            <TextField id='search-field' label="UUID/SensorID/BoardID" variant="outlined" placeholder='UUID or Sensor Id or Board Id'
                                sx={{ width: "100%" }}
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        getProvenanceData()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} mt={2} mb={2}>
                            <Button variant="contained" onClick={getProvenanceData} startIcon={<SearchIcon />} disabled={!search}>Search</Button>
                        </Grid>
                        <Grid item xs={12} mt={5}>
                            {
                                loading &&
                                <Box m={5} p={5}>
                                    <CircularProgress />
                                </Box>
                            }
                            {
                                (!loading && (!provenanceData || (Object.keys(provenanceData).length <= 1))) &&
                                <b>No Data</b>
                            }
                        </Grid>
                        {
                            (!loading && provenanceData && (Object.keys(provenanceData).length > 1)) &&
                            <React.Fragment>
                                <Grid container spacing={1.5} item xs={12} mb={5}>
                                    <Grid item xs={2}>
                                        <b>IR Label </b>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {provenanceData.ir_label}
                                    </Grid>

                                    <Grid item xs={2}>
                                        <b>Board Label</b>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {provenanceData.bb_label}
                                    </Grid>
                                    {
                                        provenanceData.sensor_state &&
                                        <>
                                            <Grid item xs={2}><b>Board State</b></Grid>
                                            <Grid item xs={4}>{provenanceData.sensor_state}</Grid>
                                        </>
                                    }
                                </Grid>
                                {/* <Grid container spacing={1.5} item xs={12} mb={5} sx={{ borderBottom: '1px dashed lightgrey', padding: '1%' }}>
                                    <Grid item xs={2}>
                                        <b>Sensor Current State </b>
                                    </Grid>
                                    <Grid item xs={4}>
                                        {provenanceData.sensor_state}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl sx={{
                                            width: "85%"
                                        }}>
                                            <InputLabel id="sensor_state_dropdown">Change Sensor State</InputLabel>
                                            <Select
                                                id="sensor_state"
                                                name="sensor_state"
                                                labelId="sensor_state_dropdown"
                                                value={sensorCurrentState.state}
                                                label="Sensor State"
                                                placeholder='Pick a State'
                                                input={<OutlinedInput label="Change Sensor State" />}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    setSensorCurrentState({
                                                        state: value
                                                    })
                                                }}
                                            >
                                                <MenuItem value={CONSTANTS.STATE.CALIBRATION} key={1}>{CONSTANTS.STATE.CALIBRATION}</MenuItem>
                                                <MenuItem value={CONSTANTS.STATE.PRE_PRODUCTION_RG} key={2}>{CONSTANTS.STATE.PRE_PRODUCTION_RG}</MenuItem>
                                                <MenuItem value={CONSTANTS.STATE.POST_PRODUCTION_RG} key={3}>{CONSTANTS.STATE.POST_PRODUCTION_RG}</MenuItem>
                                                <MenuItem value={CONSTANTS.STATE.IN_FIELD} key={4}>{CONSTANTS.STATE.IN_FIELD}</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button variant="contained" onClick={setSensorState} startIcon={<UpgradeIcon />} disabled={sensorCurrentState.state === provenanceData.sensor_state}>Update State</Button>
                                    </Grid>
                                </Grid> */}
                                <Grid container spacing={1.5} item xs={2.9}>
                                    <Grid item xs={12}>
                                        <Title color='Grey'>Tera Term</Title>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Date</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.teraterm_date}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Board in Tube</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.board_in_tube}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Gas Type</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.gas_type}
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <b>Raw 1</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.raw1}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Raw 2</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.raw2}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Raw 3</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.raw3}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Raw 4</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.raw4}
                                    </Grid> */}
                                </Grid>
                                <Divider orientation="vertical" flexItem />
                                <Grid container spacing={1.5} item xs={2.9}>
                                    <Grid item xs={12}>
                                        <Title color='Grey'>Calibration</Title>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Date</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.calibrated_on}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Times Calibrated</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.number_of_times_calibrated}
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <b>Passed</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.calibration_passed ?  'Yes' : 'No'}
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <b>Times Passed</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.number_of_times_passed}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <b>Times Failed</b>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {provenanceData.number_of_times_failed}
                                    </Grid>

                                </Grid>
                                <Divider orientation="vertical" flexItem />
                                <Grid container spacing={1.5} item xs={6}>
                                    <Grid item xs={12}>
                                        <Title color='Grey'>Red Green Test</Title>
                                    </Grid>
                                    <Grid container spacing={1.5} item xs={4}>
                                        <Grid item xs={12}>
                                            <Title color="green">Pre Production</Title>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Date Started</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.pre_production_test_started}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Date Ended</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.pre_production_test_ended}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Times Passed</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.pre_production_pass_number}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Times Failed</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.pre_production_fail_number}
                                        </Grid>
                                    </Grid>
                                    <Divider orientation="vertical" flexItem />
                                    <Grid container spacing={1.5} item xs={4}>
                                        <Grid item xs={12}>
                                            <Title color="green">Post Production</Title>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Date Started</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.post_production_test_started}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Date Ended</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.post_production_test_ended}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Times Passed</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.post_production_pass_number}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Times Failed</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.post_production_fail_number}
                                        </Grid>
                                    </Grid>
                                    <Divider orientation="vertical" flexItem />
                                    <Grid container spacing={1.5} item xs={3.9}>
                                        <Grid item xs={12}>
                                            <Title color="green">Field Test</Title>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Date Started</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.field_test_test_started}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Date Ended</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.field_test_test_ended}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Times Passed</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.field_test_pass_number}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <b>Times Failed</b>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {provenanceData.field_test_fail_number}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                        {
                            (provenanceData && (Object.keys(provenanceData).length > 1) && provenanceData.pre_production_latest_value && Object.keys(provenanceData.pre_production_latest_value).length) ?
                                < Grid item xs={12}>
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChangePanel('panel1')} key={1}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography>
                                                Latest Pre Production Value
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <pre>{JSON.stringify(provenanceData.pre_production_latest_value, null, 2)}</pre>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid> :
                                <></>
                        }
                        {
                            (provenanceData && (Object.keys(provenanceData).length > 1) && provenanceData.post_production_latest_value && Object.keys(provenanceData.post_production_latest_value).length) ?
                                < Grid item xs={12}>
                                    <Accordion expanded={expanded === 'panel2'} onChange={handleChangePanel('panel2')} key={1}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography>
                                                Latest Post Production Value
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <pre>{JSON.stringify(provenanceData.post_production_latest_value, null, 2)}</pre>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid> :
                                <></>
                        }
                        {
                            (provenanceData && (Object.keys(provenanceData).length > 1) && provenanceData.field_test_latest_value && Object.keys(provenanceData.field_test_latest_value).length) ?
                                < Grid item xs={12}>
                                    <Accordion expanded={expanded === 'panel3'} onChange={handleChangePanel('panel3')} key={1}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography>
                                                Latest Field Test Values
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <pre>{JSON.stringify(provenanceData.field_test_latest_value, null, 2)}</pre>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid> :
                                <></>
                        }
                        {
                            (provenanceData && (Object.keys(provenanceData).length > 1) && Object.keys(provenanceData.calibration).length) ?
                                < Grid item xs={12}>
                                    <Accordion expanded={expanded === 'panel4'} onChange={handleChangePanel('panel4')} key={1}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1bh-content"
                                            id="panel1bh-header"
                                        >
                                            <Typography>
                                                Calibration Values
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <pre>{JSON.stringify(provenanceData.calibration, null, 2)}</pre>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid> :
                                <></>
                        }
                    </Grid>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Title>Field Test</Title>
                    <Grid container alignContent="center" justify="center">
                        <Grid item xs={6}>
                            <FormControl sx={{
                                width: "85%"
                            }}>
                                <InputLabel id="site-dropdown-field-test">Site</InputLabel>
                                <Select
                                    id="site-select-checklist"
                                    name="sites"
                                    labelId="site-dropdown-field-test"
                                    value={payloadFieldTest.site}
                                    label="Site"
                                    placeholder='Pick a Site'
                                    input={<OutlinedInput label="Site" />}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setPayloadFieldTest(prev => ({
                                            ...prev,
                                            site: value
                                        }))
                                        getMetaData(e.target.value)
                                    }}
                                >
                                    {siteData && siteData.map(data =>
                                        <MenuItem value={data.Name} key={data._id}>{data.fullName}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl sx={{
                                width: "85%"
                            }}>
                                <InputLabel id="quantifier-dropdown-label-field-test">Quantifiers(s)</InputLabel>
                                <Select
                                    multiple
                                    id="quantifier-select-field-test"
                                    name="quantifiers"
                                    labelId="quantifier-dropdown-label-field-test"
                                    value={payloadFieldTest.quantifiers}
                                    label="Quantifiers(s)"
                                    placeholder='Pick single or multiple quantifier(s)'
                                    input={<OutlinedInput label="Quantifiers(s)" />}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        setPayloadFieldTest(prev => ({
                                            ...prev,
                                            quantifiers: typeof value === 'string' ? value.split(',') : value
                                        }))
                                    }}
                                >
                                    {quantifiers && quantifiers.map(data =>
                                        <MenuItem value={data.devEUI} key={data.devEUI}>{data.quantifier}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} mt={5}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    label="Trip Start"
                                    placeholder='Trip Start'
                                    value={payloadFieldTest.journey_start}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setPayloadFieldTest(prev => ({
                                                ...prev,
                                                journey_start: new Date(newValue),
                                            }))
                                        } else {
                                            setPayloadFieldTest(prev => ({
                                                ...prev,
                                                journey_start: null,
                                            }))
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={5} mt={5}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DateTimePicker
                                    label="Trip End"
                                    placeholder='Trip End'
                                    value={payloadFieldTest.journey_end}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setPayloadFieldTest(prev => ({
                                                ...prev,
                                                journey_end: new Date(newValue),
                                            }))
                                        } else {
                                            setPayloadFieldTest(prev => ({
                                                ...prev,
                                                journey_end: null,
                                            }))
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2} mt={5}>
                            <Button
                                variant="contained"
                                onClick={submitPayload}
                                size="large"
                                startIcon={<PublishIcon />}
                                disabled={!payloadFieldTest.site || !(payloadFieldTest.quantifiers && payloadFieldTest.quantifiers.length) ||
                                    !payloadFieldTest.journey_start || !payloadFieldTest.journey_end}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                    {!loading && <Grid container rowSpacing={2} columnSpacing={2} mt={2}>
                        <Grid item xs={12} mt={2}>
                            {
                                (fieldTestData && fieldTestData.length) ?
                                    <DataGrid
                                        rows={fieldTestData}
                                        columns={columns}
                                        autoHeight={true}
                                        loading={loading}
                                        rowCount={fieldTestData.length}
                                        components={{
                                            Toolbar: CustomToolbar,
                                            Footer: CustomFooterTotalComponent
                                        }}
                                    /> : <React.Fragment></React.Fragment >
                            }
                        </Grid>
                    </Grid>}
                    {loading &&
                        <Box m={5} p={5}>
                            <CircularProgress />
                        </Box>
                    }
                </TabPanel>
            </Box>
        </React.Fragment >
    )
}