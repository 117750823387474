import axios from 'axios';
import Items from './items';
import MenuCard from "./Menu"
import Built from './Can_Built';
import Kpi from './../Sensor/Kpi';
import Box from '@mui/material/Box';
import View from './../Sensor/View';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import AddNotes from './../Notes/Add';
import ViewNotes from './../Notes/View';
import MainListItems from './listItems';
import Paper from '@mui/material/Paper';
import ViewSafety from "../Safety/View";
import AddReports from './../Reports/Add';
import Avatar from '@mui/material/Avatar';
import ViewReports from './../Reports/View';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Packet from "./../Sensor/Packet-loss";
import ChecklistAdd from './../Checklist/Add';
import ViewChecklist from './../Checklist/View';
import AddQuantifier from './../Quantifier/Add';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import CONSTANTS from '../../helpers/constants';
import ViewEquipments from './../Equipment/View';
import ViewQuantifier from './../Quantifier/View';
import LocalStorage from "../Login/local-storage";
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Calibration from './../Sensor/Calibration';
import TopInStock from '../Dashboard/items_stock';
import React, { useEffect, useState } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import logo from '../../assets/ems-logo-only-01.png';
import ReportQuantifier from './../Quantifier/Report';
import SensorLocation from "./../Sensor/sensor-location"
import JourneyManagement from "../Safety/journey_management";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgress from '@mui/material/CircularProgress';
import SiteSpecificSafety from '../Safety/site_specific_safety';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://ems-inc.ca">
                Environmental Material Science
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const mdTheme = createTheme();

export default function DashboardContent(props) {
    let ls = new LocalStorage()
    const [open, setOpen] = useState(true)
    const [loading, setLoading] = useState(true)
    const [quantInventory, setQuantInventory] = useState({})
    const [zohoToken, setZohoToken] = useState(ls.getLocalStorageItemZoho().token)

    function toggleDrawer() {
        setOpen(!open)
    };
    async function checkAndUpdateInventoryData(token) {
        let refresh_token = ls.getLocalStorageItemZohoRefresh()
        let current_zoho_token = ls.getLocalStorageItemZoho().token
        if (refresh_token && !zohoToken) {
            let payload_data = {
                'refresh_token': refresh_token,
                'client_id': process.env.REACT_APP_ZOHO_CLIENT_ID,
                'client_secret': process.env.REACT_APP_ZOHO_CLIENT_SECRET,
                'redirect_uri': `${CONSTANTS.DEVELOPMENT_URL_APP}/dashboard`,
                'grant_type': 'refresh_token',
            }
            await axios.post(`${CONSTANTS.ZOHO_PROXY_SERVER}`, payload_data,
                {
                    headers: {
                        'authorization': `Bearer ${token}`,
                        'Target-URL': CONSTANTS.ZOHO_GET_TOKEN_API,
                        'Type': 'FormData'
                    }
                }).then((response) => {
                    current_zoho_token = response.data.access_token
                    setZohoToken(current_zoho_token)
                    ls.setZohoTokenAndUser(token, current_zoho_token)
                })
        } else if (!refresh_token) {
            let ZOHO_CODE_API = CONSTANTS.ZOHO_REFRESH_TOKEN_API.replace(':client_id',
                process.env.REACT_APP_ZOHO_CLIENT_ID).replace(':scope', CONSTANTS.ZOHO_SCOPE)
            let link = window.location.href
            // Remove all the strings (domain name etc.) before the # sign. After which the params starts.
            link = link.split('#').pop()
            let urlParams = new URLSearchParams(link)
            let zoho_code = urlParams.get('code')
            if (zoho_code) {
                let payload_data = {
                    'grant_type': 'authorization_code',
                    'client_id': process.env.REACT_APP_ZOHO_CLIENT_ID,
                    'client_secret': process.env.REACT_APP_ZOHO_CLIENT_SECRET,
                    'redirect_uri': `${CONSTANTS.DEVELOPMENT_URL_APP}/dashboard`,
                    'code': zoho_code,
                }
                await axios.post(`${CONSTANTS.ZOHO_PROXY_SERVER}`, payload_data,
                    {
                        headers: {
                            'authorization': `Bearer ${token}`,
                            'Target-URL': CONSTANTS.ZOHO_GET_TOKEN_API,
                            'Type': 'FormData'
                        }
                    })
                    .then(async (response) => {
                        current_zoho_token = response.data.access_token
                        if (current_zoho_token && current_zoho_token !== zohoToken) {
                            setZohoToken(current_zoho_token)
                            ls.setZohoTokenAndUser(token, current_zoho_token)
                        }
                        if (response.data.refresh_token) {
                            ls.setLocalStorageItem(response.data.refresh_token, 'ZOHO_REFRESH')
                        }
                    })
            } else {
                window.location.replace(ZOHO_CODE_API)
            }
        }
        if (current_zoho_token) {
            await axios.get(`${CONSTANTS.ZOHO_PROXY_SERVER}`,
                {
                    headers: {
                        'authorization': `Bearer ${token}`,
                        'Zoho-Auth': `Zoho-oauthtoken ${current_zoho_token}`,
                        'Target-URL': CONSTANTS.ZOHO_INVENTORY_API
                    }
                })
                .then(async (response) => {
                    const headers = {
                        'authorization': 'Bearer ' + token,
                    }
                    await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.UPDATE_INVENTORY}`, response.data.items, { headers })
                        .then(() => setLoading(false))
                })
        }
    }
    useEffect(() => {
        if (props.token) {
            // setLoading(true)
            checkAndUpdateInventoryData(props.token)
        }
        // eslint-disable-next-line
    }, [props.token])
    return (
        <React.Fragment>
            {loading &&
                <Box m={10} p={10}>
                    <CircularProgress />
                </Box>
            }
            {!loading && <ThemeProvider theme={mdTheme}>
                <Box sx={{ display: 'flex' }} >
                    <CssBaseline />
                    <AppBar position="absolute" open={open} style={{ background: "black" }}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <Avatar src={logo} />
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Venato
                            </Typography>
                            {/* <IconButton color="inherit">
                            <Badge badgeContent={4} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                            <MenuCard changeLoginStatus={props.changeLoginStatus} />
                        </Toolbar>
                    </AppBar>
                    <Drawer variant="permanent" open={open}>
                        <Toolbar
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                px: [1],
                            }}
                        >
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Toolbar>
                        <Divider />
                        <List component="nav">
                            <MainListItems />
                            {/* <Divider sx={{ my: 1 }} />
                        {secondaryListItems} */}
                        </List>
                    </Drawer>
                    {/* This Box will be replaced by other components i.e. SensorBoard, Quantifiers etc. */}
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        {
                            (props.page === CONSTANTS.PAGE_DASHBOARD) &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    {/* Chart */}
                                    <Grid item xs={12} md={8} lg={8}>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {
                                                zohoToken &&
                                                <TopInStock zohoToken={zohoToken} token={props.token} setQuantInventory={setQuantInventory} />

                                            }
                                        </Paper>
                                    </Grid>
                                    {/* Can be built */}
                                    <Grid item xs={12} md={4} lg={4}>
                                        <Paper
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {quantInventory && <Built quantInventory={quantInventory} />}

                                        </Paper>
                                    </Grid>
                                    {/* Recent Items */}
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <Items token={props.token} title={'Important Inventory Items'} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <Items token={props.token} title={'Low Stock Inventory Items'} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_SENSOR_BOARDS_ADD &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <Calibration title={'Sensor Testing'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <Calibration title={'Sensor Calibration'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <Calibration title={'Equipment Testing'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_SENSOR_BOARDS_VIEW &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <View title={'Sensors'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_QUANTIFIER_ADD &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <AddQuantifier title={'Add Quantifier'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_QUANTIFIER_VIEW &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <ViewQuantifier title={'View Quantifiers'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_QUANTIFIER_REPORT &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <ReportQuantifier title={'Quantifier Reports'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_PACKET_LOSS &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <Packet title={'Packet Loss'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_SENSOR_LOCATION &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <SensorLocation title={'Sensor Location'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>

                        }
                        {
                            props.page === CONSTANTS.PAGE_SENSOR_KPI &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <Kpi title={'Key Performance Indicators'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_ADD &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <ChecklistAdd title={'Field Checklist'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_FIELD_NOTES_CHECKLIST_VIEW &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <ViewChecklist title={'View Checklist'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_FIELD_NOTES_ADD &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <AddNotes title={'Add Notes'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_FIELD_NOTES_VIEW &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <ViewNotes title={'View Notes'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_EQUIPMENT_VIEW &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <ViewEquipments title={'View Equipments'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page.includes(CONSTANTS.PAGE_FIELD_REPORTS_ADD_CHECK) &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <AddReports title={'Add Report'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_FIELD_REPORTS_VIEW &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <ViewReports title={'View Reports'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_FIELD_SAFETY_NEW_SITE &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <SiteSpecificSafety title={'New Site Specific Safety Form'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                            props.page === CONSTANTS.PAGE_FIELD_SAFETY_VIEW &&
                            <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                            <ViewSafety title={'Submitted Safety Forms'} token={props.token} />
                                        </Paper>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ pt: 4 }} />
                            </Container>
                        }
                        {
                             props.page === CONSTANTS.PAGE_FIELD_SAFETY_JOURNEY_MANAGEMENT &&
                             <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
                                 <Grid container spacing={3}>
                                     <Grid item xs={12}>
                                         <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                             <JourneyManagement title={'Journey Management Form'} token={props.token} />
                                         </Paper>
                                     </Grid>
                                 </Grid>
                                 <Copyright sx={{ pt: 4 }} />
                             </Container>
                        }
                    </Box>
                </Box>
            </ThemeProvider>}
        </React.Fragment>
    );
}