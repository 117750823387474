import axios from 'axios';
import S3 from 'aws-sdk/clients/s3';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Stack from '@mui/material/Stack';
import React, { useState } from "react";
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import CONSTANTS from '../../helpers/constants';
import ImageList from '@mui/material/ImageList';
import Typography from '@mui/material/Typography';
import ImageListItem from '@mui/material/ImageListItem';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CircularProgress from '@mui/material/CircularProgress';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

let s3 = new S3({
    "accessKeyId": process.env.REACT_APP_ACCESS_KEY,
    "secretAccessKey": process.env.REACT_APP_SECRET_ACCESS_KEY,
    "region": process.env.REACT_APP_REGION
});

export default function EditNotes(props) {
    const [data, setData] = useState({new_categories: [""]})
    const [loading, setLoading] = useState(false)
    const [payloadSubmit] = useState({
        _id: props.data._id
    })

    const handleSubmit = (event) => {
        event.preventDefault()
        setLoading(true)
        operations().then(() => sendData())
    }
    async function operations() {
        if (props.data.special_tools !== data.special_tools) {
            payloadSubmit['special_tools'] = data.special_tools
        }
        if (props.data.issues !== data.issues) {
            payloadSubmit['issues'] = data.issues
        }
        if (props.data.next_steps !== data.next_steps) {
            payloadSubmit['next_steps'] = data.next_steps
        }
        if (props.data.site_pictures !== data.site_pictures) {
            // s3 add site
            await uploadToS3('site_pictures', 'Site')
        }
        for (let index in data.new_categories) {
             if (data.new_categories[index]) {
                let element = data.new_categories[index]
                if (props.data[`${element}`] !== data[`${element}`]) {
                    await uploadToS3(element, element)
                }
            }
        }
        if (props.data.new_categories !== data.new_categories && data.new_categories.length && data.new_categories[0] !== "") {
            payloadSubmit['new_categories'] = data.new_categories
        }
        if (props.data.general_pictures !== data.general_pictures) {
            // s3 add site
            await uploadToS3('general_pictures', 'General')
        }
        if (props.data.type === 'quantifier' || data.type === 'quantifier/distributor') {
            if (props.data.power_pictures !== data.power_pictures) {
                // s3 add power
                await uploadToS3('power_pictures', 'Power')
            }
            if (props.data.quant_pictures !== data.quant_pictures) {
                // s3 add quantifier
                await uploadToS3('quant_pictures', 'Quantifier')
            }
            if (props.data.gateway_pictures !== data.gateway_pictures) {
                // s3 add gateway
                await uploadToS3('gateway_pictures', 'Gateway')
            }
            if (props.data.distributor_injection !== data.distributor_injection) {
                // s3 add gateway
                await uploadToS3('distributor_injection', 'Distributor/Injection')
            }
            if (props.data.distributor_power !== data.distributor_power) {
                // s3 add gateway
                await uploadToS3('distributor_power', 'Distributor/Power')
            }
            if (props.data.distributor_water !== data.distributor_water) {
                // s3 add gateway
                await uploadToS3('distributor_water', 'Distributor/Water')
            }
        }
        if (props.data.type === 'distributor') {
            if (props.data.distributor_injection !== data.distributor_injection) {
                // s3 add gateway
                await uploadToS3('distributor_injection', 'Distributor/Injection')
            }
            if (props.data.distributor_power !== data.distributor_power) {
                // s3 add gateway
                await uploadToS3('distributor_power', 'Distributor/Power')
            }
            if (props.data.distributor_water !== data.distributor_water) {
                // s3 add gateway
                await uploadToS3('distributor_water', 'Distributor/Water')
            }
        }
        return
    }
    async function uploadToS3(key, location) {
        let date = new Date()
        let datestring = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " +
            date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        let resultant_links = []
        if (data[key] && data[key].length) {
            for (let i = 0; i < data[key].length; i++) {
                let type = data[key][i].type
                type = type.split('/')
                type = type[1]
                let params = {
                    Bucket: process.env.REACT_APP_BUCKET_NOTES,
                    Key: `${props.data.site}/${location}/${datestring}-${i}.${type}`,
                    Body: data[key][i]
                }
                // setting the specific index image's link
                resultant_links.push(`${props.data.site}/${location}/${datestring}-${i}.${type}`)
                await s3.upload(params).promise()
            }
            payloadSubmit[key] = resultant_links
            if (props.data[key]) {
                let temp_pictures = props.data[key].concat(resultant_links)
                payloadSubmit[key] = temp_pictures
            }
            resultant_links = []
        }
    }
    async function sendData() {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_UPDATE_API}`, payloadSubmit, { headers })
            .then(() => {
                setLoading(false)
                props.handleDialogClose()
                props.getNotes(props.token, true)
            })
    }

    function pasteImage(event) {
        if (event.clipboardData && event.clipboardData.files.length) {
            let new_images = []
            const { clipboardData: { files } } = event;
            const { target: { name } } = event;
            if (files && files.length) {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].type.includes("image"))
                        new_images.push(files[i])
                }
                setData(prev => ({
                    ...prev,
                    [name]: new_images,
                }))
            }
        }
    }

    function toPascalCase(str) {
        return str
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      }
    

    const downloadImageAWS = (type) => {
        setLoading(true)
        let payload = {
            key: props.data[type],
            type,
            _id: props.data._id
        }
        getDataAWS(payload, type)
    }
    function uploadData(event) {
        event.preventDefault();
        const { target: { files } } = event;
        const { target: { name } } = event;
        let new_images = []
        if (files && files.length) {
            for (let i = 0; i < files.length; i++) {
                new_images.push(files[i])
            }
            setData(prev => ({
                ...prev,
                [name]: new_images,
            }))
        }
    }
    async function getDataAWS(payload, type) {
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.NOTES_IMAGES_API}`, payload, { headers })
            .then((result) => {
                setData(prev => ({
                    ...prev,
                    [`${type}_aws`]: result.data.data
                }))
                setLoading(false)
            })
    }
    return (
        <React.Fragment>
            <Title>{`${props.title} - ${props.data.site} - ${new Date(props.data.date)}`}</Title>
            {loading &&
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box m={5} p={5}>
                        <CircularProgress />
                    </Box>
                </Grid>
            }
            <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
            {!loading &&
                <Grid container rowSpacing={5} columnSpacing={2} mt={2}>
                    {/* General Picture add/load/display */}
                    {(!data.general_pictures_aws || !data.general_pictures_aws.length) ?
                        <React.Fragment>
                            <Grid item xs={6}>
                                <Stack spacing={2}>
                                    <TextField
                                        multiline
                                        placeholder={(data.general_pictures && data.general_pictures.length) ? ` Selected: ${data.general_pictures.length}\n ${data.general_pictures.map(value => value.name)}` : ""}
                                        label={"Selected General Picture(s)"}
                                        style={{
                                            width: "50%",
                                            alignSelf: 'center'
                                        }}
                                        color="secondary"
                                        variant="filled"
                                        focused
                                        name="general_pictures"
                                        onPaste={pasteImage}
                                    />
                                    <Button variant="outlined" component="label" startIcon={<PhotoCameraIcon />} style={{
                                        width: "50%",
                                        alignSelf: 'center'
                                    }}>
                                        Select General Pic(s)
                                        <input type="file" multiple accept="image/*" name="general_pictures" onChange={uploadData} hidden />
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    (props.data.general_pictures && props.data.general_pictures.length) ?
                                        <Stack spacing={2}>
                                            <TextField
                                                multiline
                                                placeholder={` Selected: ${props.data.general_pictures.length}`}
                                                label={"Previous General Picture(s)"}
                                                inputProps={
                                                    { readOnly: true }
                                                }
                                                style={{
                                                    width: "50%",
                                                    alignSelf: 'center'
                                                }}
                                                color="secondary"
                                                variant="filled"
                                                focused
                                            />
                                            <Button variant="outlined" component="label" onClick={() => downloadImageAWS('general_pictures')} startIcon={<CloudDownloadIcon />} style={{
                                                width: "50%",
                                                alignSelf: 'center'
                                            }}>
                                                Load General Pic(s)
                                            </Button>
                                        </Stack> : <React.Fragment></React.Fragment>
                                }

                            </Grid>
                        </React.Fragment> : 
                        <React.Fragment></React.Fragment>
                    }
                    {
                        (data.general_pictures_aws && data.general_pictures_aws.length) ?
                            <React.Fragment>
                                <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                    color: 'black',
                                    marginLeft: "2%"
                                }}>
                                    General Pictures
                                </Typography>
                                <ImageList cols={3}>
                                    {data.general_pictures_aws.map((item, key) => (
                                        <ImageListItem key={key}>
                                            <img
                                                src={`${item}`}
                                                alt={'General Pictures'}
                                                loading="lazy"
                                                style={{ objectFit: "contain" }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </React.Fragment>
                            :
                            <React.Fragment />
                    }
                    {/* Site Picture add/load/display */}
                    <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
                    {(!data.site_pictures_aws || !data.site_pictures_aws.length) ?
                        <React.Fragment>
                            <Grid item xs={6}>
                                <Stack spacing={2}>
                                    <TextField
                                        multiline
                                        placeholder={(data.site_pictures && data.site_pictures.length) ? ` Selected: ${data.site_pictures.length}\n ${data.site_pictures.map(value => value.name)}` : ""}
                                        label={"Selected Site Picture(s)"}
                                        style={{
                                            width: "50%",
                                            alignSelf: 'center'
                                        }}
                                        color="secondary"
                                        variant="filled"
                                        focused
                                        name="site_pictures"
                                        onPaste={pasteImage}
                                    />
                                    <Button variant="outlined" component="label" startIcon={<PhotoCameraIcon />} style={{
                                        width: "50%",
                                        alignSelf: 'center'
                                    }}>
                                        Select Site Pic(s)
                                        <input type="file" multiple accept="image/*" name="site_pictures" onChange={uploadData} hidden />
                                    </Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    (props.data.site_pictures && props.data.site_pictures.length) ?
                                        <Stack spacing={2}>
                                            <TextField
                                                multiline
                                                placeholder={` Selected: ${props.data.site_pictures.length}`}
                                                label={"Previous Site Picture(s)"}
                                                inputProps={
                                                    { readOnly: true }
                                                }
                                                style={{
                                                    width: "50%",
                                                    alignSelf: 'center'
                                                }}
                                                color="secondary"
                                                variant="filled"
                                                focused
                                            />
                                            <Button variant="outlined" component="label" onClick={() => downloadImageAWS('site_pictures')} startIcon={<CloudDownloadIcon />} style={{
                                                width: "50%",
                                                alignSelf: 'center'
                                            }}>
                                                Load Site Pic(s)
                                            </Button>
                                        </Stack> : <React.Fragment></React.Fragment>
                                }

                            </Grid> 
                        </React.Fragment>
                        :<React.Fragment></React.Fragment>
                    }
                    {
                        (data.site_pictures_aws && data.site_pictures_aws.length) ?
                            <React.Fragment>
                                <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                    color: 'black',
                                    marginLeft: "2%"
                                }}>
                                    Site Pictures
                                </Typography>
                                <ImageList cols={3}>
                                    {data.site_pictures_aws.map((item, key) => (
                                        <ImageListItem key={item}>
                                            <img
                                                src={`${item}`}
                                                alt={'Site Pictures'}
                                                loading="lazy"
                                                style={{ objectFit: "contain" }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </React.Fragment>
                            :
                            <React.Fragment />
                    }
                    <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
                    {
                        (props.data.type === 'quantifier' || props.data.type === 'quantifier/distributor') &&
                        <React.Fragment>
                            {/* Power Picture add/load/display */}
                            {(!data.power_pictures_aws || !data.power_pictures_aws.length) ?
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <TextField
                                                multiline
                                                placeholder={(data.power_pictures && data.power_pictures.length) ? ` Selected: ${data.power_pictures.length}\n ${data.power_pictures.map(value => value.name)}` : ""}
                                                label={"Selected Power Picture(s)"}
                                                style={{
                                                    width: "50%",
                                                    alignSelf: 'center'
                                                }}
                                                color="secondary"
                                                variant="filled"
                                                focused
                                                name="power_pictures"
                                                onPaste={pasteImage}
                                            />
                                            <Button variant="outlined" component="label" startIcon={<PhotoCameraIcon />} style={{
                                                width: "50%",
                                                alignSelf: 'center'
                                            }}>
                                                Select Power Pic(s)
                                                <input type="file" multiple accept="image/*" name="power_pictures" onChange={uploadData} hidden />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            (props.data.power_pictures && props.data.power_pictures.length) ?
                                                <Stack spacing={2}>
                                                    <TextField
                                                        multiline
                                                        placeholder={` Selected: ${props.data.power_pictures.length}`}
                                                        label={"Previous Power Picture(s)"}
                                                        inputProps={
                                                            { readOnly: true }
                                                        }
                                                        style={{
                                                            width: "50%",
                                                            alignSelf: 'center'
                                                        }}
                                                        color="secondary"
                                                        variant="filled"
                                                        focused
                                                    />
                                                    <Button variant="outlined" component="label" onClick={() => downloadImageAWS('power_pictures')} startIcon={<CloudDownloadIcon />} style={{
                                                        width: "50%",
                                                        alignSelf: 'center'
                                                    }}>
                                                        Load Power Pic(s)
                                                    </Button>
                                                </Stack> : <React.Fragment></React.Fragment>
                                        }

                                    </Grid>
                                </React.Fragment>
                                : <React.Fragment></React.Fragment>}
                            {
                                (data.power_pictures_aws && data.power_pictures_aws.length) ?
                                    <React.Fragment>
                                        <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                            color: 'black',
                                            marginLeft: "2%"
                                        }}>
                                            Power Pictures
                                        </Typography>
                                        <ImageList cols={3}>
                                            {data.power_pictures_aws.map((item, key) => (
                                                <ImageListItem key={item}>
                                                    <img
                                                        src={`${item}`}
                                                        alt={'Power Pictures'}
                                                        loading="lazy"
                                                        style={{ objectFit: "contain" }}
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                            <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
                            {/* Gateway Picture add/load/display */}
                            {(!data.gateway_pictures_aws || !data.gateway_pictures_aws.length) ?
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <TextField
                                                multiline
                                                placeholder={(data.gateway_pictures && data.gateway_pictures.length) ? ` Selected: ${data.gateway_pictures.length}\n ${data.gateway_pictures.map(value => value.name)}` : ""}
                                                label={"Selected Gateway Picture(s)"}
                                                style={{
                                                    width: "50%",
                                                    alignSelf: 'center'
                                                }}
                                                color="secondary"
                                                variant="filled"
                                                focused
                                                name="gateway_pictures"
                                                onPaste={pasteImage}
                                            />
                                            <Button variant="outlined" component="label" startIcon={<PhotoCameraIcon />} style={{
                                                width: "50%",
                                                alignSelf: 'center'
                                            }}>
                                                Select Gateway Pic(s)
                                                <input type="file" multiple accept="image/*" name="gateway_pictures" onChange={uploadData} hidden />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            (props.data.gateway_pictures && props.data.gateway_pictures.length) ?
                                                <Stack spacing={2}>
                                                    <TextField
                                                        multiline
                                                        placeholder={` Selected: ${props.data.gateway_pictures.length}`}
                                                        label={"Previous Gateway Picture(s)"}
                                                        inputProps={
                                                            { readOnly: true }
                                                        }
                                                        style={{
                                                            width: "50%",
                                                            alignSelf: 'center'
                                                        }}
                                                        color="secondary"
                                                        variant="filled"
                                                        focused
                                                    />
                                                    <Button variant="outlined" component="label" onClick={() => downloadImageAWS('gateway_pictures')} startIcon={<CloudDownloadIcon />} style={{
                                                        width: "50%",
                                                        alignSelf: 'center'
                                                    }}>
                                                        Load Gateway Pic(s)
                                                    </Button>
                                                </Stack> : <React.Fragment></React.Fragment>
                                        }

                                    </Grid> 
                                </React.Fragment>: <React.Fragment></React.Fragment>}
                            {
                                (data.gateway_pictures_aws && data.gateway_pictures_aws.length) ?
                                    <React.Fragment>
                                        <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                            color: 'black',
                                            marginLeft: "2%"
                                        }}>
                                            Gateway Pictures
                                        </Typography>
                                        <ImageList cols={3}>
                                            {data.gateway_pictures_aws.map((item, key) => (
                                                <ImageListItem key={item}>
                                                    <img
                                                        src={`${item}`}
                                                        alt={'Gateway Pictures'}
                                                        loading="lazy"
                                                        style={{ objectFit: "contain" }}
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                             <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
                            {/* Quantifier Picture add/load/display */}
                            {(!data.quant_pictures_aws || !data.quant_pictures_aws.length) ?
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <TextField
                                                multiline
                                                placeholder={(data.quant_pictures && data.quant_pictures.length) ? ` Selected: ${data.quant_pictures.length}\n ${data.quant_pictures.map(value => value.name)}` : ""}
                                                label={"Selected Quantifier Picture(s)"}
                                                style={{
                                                    width: "50%",
                                                    alignSelf: 'center'
                                                }}
                                                color="secondary"
                                                variant="filled"
                                                focused
                                                name="quant_pictures"
                                                onPaste={pasteImage}
                                            />
                                            <Button variant="outlined" component="label" startIcon={<PhotoCameraIcon />} style={{
                                                width: "50%",
                                                alignSelf: 'center'
                                            }}>
                                                Select Quantifier Pic(s)
                                                <input type="file" multiple accept="image/*" name="quant_pictures" onChange={uploadData} hidden />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            (props.data.quant_pictures && props.data.quant_pictures.length) ?
                                                <Stack spacing={2}>
                                                    <TextField
                                                        multiline
                                                        placeholder={` Selected: ${props.data.quant_pictures.length}`}
                                                        label={"Previous Quantifier Picture(s)"}
                                                        inputProps={
                                                            { readOnly: true }
                                                        }
                                                        style={{
                                                            width: "50%",
                                                            alignSelf: 'center'
                                                        }}
                                                        color="secondary"
                                                        variant="filled"
                                                        focused
                                                    />
                                                    <Button variant="outlined" component="label" onClick={() => downloadImageAWS('quant_pictures')} startIcon={<CloudDownloadIcon />} style={{
                                                        width: "50%",
                                                        alignSelf: 'center'
                                                    }}>
                                                        Load Quantifier Pic(s)
                                                    </Button>
                                                </Stack> : <React.Fragment></React.Fragment>
                                        }
                                    </Grid> 
                                </React.Fragment>: <React.Fragment></React.Fragment>}
                            {
                                (data.quant_pictures_aws && data.quant_pictures_aws.length) ?
                                    <React.Fragment>
                                        <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                            color: 'black',
                                            marginLeft: "2%"
                                        }}>
                                            Quantifier Pictures
                                        </Typography>
                                        <ImageList cols={3}>
                                            {data.quant_pictures_aws.map((item, key) => (
                                                <ImageListItem key={item}>
                                                    <img
                                                        src={`${item}`}
                                                        alt={'Quantifier Pictures'}
                                                        loading="lazy"
                                                        style={{ objectFit: "contain" }}
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>
                    }
                    <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
                    {
                        (props.data.type === 'distributor' || props.data.type === 'quantifier/distributor') &&
                        <React.Fragment>
                            {/* Distributor Power Picture add/load/display */}
                            {(!data.distributor_power_aws || !data.distributor_power_aws.length) ?
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <TextField
                                                multiline
                                                placeholder={(data.distributor_power && data.distributor_power.length) ? ` Selected: ${data.distributor_power.length}\n ${data.distributor_power.map(value => value.name)}` : ""}
                                                label={"Selected Distributor Power Picture(s)"}
                                                style={{
                                                    width: "50%",
                                                    alignSelf: 'center'
                                                }}
                                                color="secondary"
                                                variant="filled"
                                                focused
                                                name="distributor_power"
                                                onPaste={pasteImage}
                                            />
                                            <Button variant="outlined" component="label" startIcon={<PhotoCameraIcon />} style={{
                                                width: "50%",
                                                alignSelf: 'center'
                                            }}>
                                                Select Distributor Power Pic(s)
                                                <input type="file" multiple accept="image/*" name="distributor_power" onChange={uploadData} hidden />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            (props.data.distributor_power && props.data.distributor_power.length) ?
                                                <Stack spacing={2}>
                                                    <TextField
                                                        multiline
                                                        placeholder={` Selected: ${props.data.distributor_power.length}`}
                                                        label={"Previous Distributor Power Picture(s)"}
                                                        inputProps={
                                                            { readOnly: true }
                                                        }
                                                        style={{
                                                            width: "50%",
                                                            alignSelf: 'center'
                                                        }}
                                                        color="secondary"
                                                        variant="filled"
                                                        focused
                                                    />
                                                    <Button variant="outlined" component="label" onClick={() => downloadImageAWS('distributor_power')} startIcon={<CloudDownloadIcon />} style={{
                                                        width: "50%",
                                                        alignSelf: 'center'
                                                    }}>
                                                        Load Distributor Power Pic(s)
                                                    </Button>
                                                </Stack> : <React.Fragment></React.Fragment>
                                        }
                                    </Grid> 
                                </React.Fragment>: <React.Fragment></React.Fragment>}
                            {
                                (data.distributor_power_aws && data.distributor_power_aws.length) ?
                                    <React.Fragment>
                                        <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                            color: 'black',
                                            marginLeft: "2%"
                                        }}>
                                            Distributor Power Pictures
                                        </Typography>
                                        <ImageList cols={3}>
                                            {data.distributor_power_aws.map((item, key) => (
                                                <ImageListItem key={item}>
                                                    <img
                                                        src={`${item}`}
                                                        alt={'Distributor Power Pictures'}
                                                        loading="lazy"
                                                        style={{ objectFit: "contain" }}
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                             <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
                            {/* Distributor Injection Picture add/load/display */}
                            {(!data.distributor_injection_aws || !data.distributor_injection_aws.length) ?
                                 <React.Fragment>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <TextField
                                                multiline
                                                placeholder={(data.distributor_injection && data.distributor_injection.length) ? ` Selected: ${data.distributor_injection.length}\n ${data.distributor_injection.map(value => value.name)}` : ""}
                                                label={"Selected Distributor Injection Picture(s)"}
                                                style={{
                                                    width: "50%",
                                                    alignSelf: 'center'
                                                }}
                                                color="secondary"
                                                variant="filled"
                                                focused
                                                name="distributor_injection"
                                                onPaste={pasteImage}
                                            />
                                            <Button variant="outlined" component="label" startIcon={<PhotoCameraIcon />} style={{
                                                width: "50%",
                                                alignSelf: 'center'
                                            }}>
                                                Select Distributor Injection Pic(s)
                                                <input type="file" multiple accept="image/*" name="distributor_injection" onChange={uploadData} hidden />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            (props.data.distributor_injection && props.data.distributor_injection.length) ?
                                                <Stack spacing={2}>
                                                    <TextField
                                                        multiline
                                                        placeholder={` Selected: ${props.data.distributor_injection.length}`}
                                                        label={"Previous Distributor Injection Picture(s)"}
                                                        inputProps={
                                                            { readOnly: true }
                                                        }
                                                        style={{
                                                            width: "50%",
                                                            alignSelf: 'center'
                                                        }}
                                                        color="secondary"
                                                        variant="filled"
                                                        focused
                                                    />
                                                    <Button variant="outlined" component="label" onClick={() => downloadImageAWS('distributor_injection')} startIcon={<CloudDownloadIcon />} style={{
                                                        width: "50%",
                                                        alignSelf: 'center'
                                                    }}>
                                                        Load Distributor Injection Pic(s)
                                                    </Button>
                                                </Stack> : <React.Fragment></React.Fragment>
                                        }
                                    </Grid>
                                </React.Fragment> : <React.Fragment></React.Fragment>}
                            {
                                (data.distributor_injection_aws && data.distributor_injection_aws.length) ?
                                    <React.Fragment>
                                        <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                            color: 'black',
                                            marginLeft: "2%"
                                        }}>
                                            Distributor Injection Pictures
                                        </Typography>
                                        <ImageList cols={3}>
                                            {data.distributor_injection_aws.map((item, key) => (
                                                <ImageListItem key={item}>
                                                    <img
                                                        src={`${item}`}
                                                        alt={'Distributor Injection'}
                                                        loading="lazy"
                                                        style={{ objectFit: "contain" }}
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                            <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
                            {/* Distributor Water Picture add/load/display */}
                            {(!data.distributor_water_aws || !data.distributor_water_aws.length) ?
                                <React.Fragment>
                                    <Grid item xs={6}>
                                        <Stack spacing={2}>
                                            <TextField
                                                multiline
                                                placeholder={(data.distributor_water && data.distributor_water.length) ? ` Selected: ${data.distributor_water.length}\n ${data.distributor_water.map(value => value.name)}` : ""}
                                                label={"Selected Distributor Water Picture(s)"}
                                                style={{
                                                    width: "50%",
                                                    alignSelf: 'center'
                                                }}
                                                color="secondary"
                                                variant="filled"
                                                focused
                                                name="distributor_water"
                                                onPaste={pasteImage}
                                            />
                                            <Button variant="outlined" component="label" startIcon={<PhotoCameraIcon />} style={{
                                                width: "50%",
                                                alignSelf: 'center'
                                            }}>
                                                Select Distributor Water Pic(s)
                                                <input type="file" multiple accept="image/*" name="distributor_water" onChange={uploadData} hidden />
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            (props.data.distributor_water && props.data.distributor_water.length) ?
                                                <Stack spacing={2}>
                                                    <TextField
                                                        multiline
                                                        placeholder={` Selected: ${props.data.distributor_water.length}`}
                                                        label={"Previous Distributor Water Picture(s)"}
                                                        inputProps={
                                                            { readOnly: true }
                                                        }
                                                        style={{
                                                            width: "50%",
                                                            alignSelf: 'center'
                                                        }}
                                                        color="secondary"
                                                        variant="filled"
                                                        focused
                                                    />
                                                    <Button variant="outlined" component="label" onClick={() => downloadImageAWS('distributor_water')} startIcon={<CloudDownloadIcon />} style={{
                                                        width: "50%",
                                                        alignSelf: 'center'
                                                    }}>
                                                        Load Distributor Water Pic(s)
                                                    </Button>
                                                </Stack> : <React.Fragment></React.Fragment>
                                        }
                                    </Grid> 
                                </React.Fragment>: <React.Fragment></React.Fragment>}
                            {
                                (data.distributor_water_aws && data.distributor_water_aws.length) ?
                                    <React.Fragment>
                                        <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                            color: 'black',
                                            marginLeft: "2%"
                                        }}>
                                            Distributor Water Pictures
                                        </Typography>
                                        <ImageList cols={3}>
                                            {data.distributor_water_aws.map((item, key) => (
                                                <ImageListItem key={item}>
                                                    <img
                                                        src={`${item}`}
                                                        alt={'Distributor Water Pictures'}
                                                        loading="lazy"
                                                        style={{ objectFit: "contain" }}
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>
                    }
                    {
                       (props.data.new_categories && props.data.new_categories.length) ?
                       <>
                            {
                                props.data.new_categories.map(category=> (
                                    <>
                                        <Grid item xs={12}>
                                        {
                                            (props.data[category] && props.data[category].length && !data[`${category}_aws`]) ?
                                                <Stack spacing={2}>
                                                    <TextField
                                                        multiline
                                                        placeholder={` Selected: ${props.data[category].length}`}
                                                        label={`Previous ${category} Picture(s)`}
                                                        inputProps={
                                                            { readOnly: true }
                                                        }
                                                        style={{
                                                            width: "50%",
                                                            alignSelf: 'center'
                                                        }}
                                                        color="secondary"
                                                        variant="filled"
                                                        focused
                                                    />
                                                    <Button variant="outlined" component="label" onClick={() => downloadImageAWS(category)} startIcon={<CloudDownloadIcon />} style={{
                                                        width: "50%",
                                                        alignSelf: 'center'
                                                    }}>
                                                        {`Load ${category} Pic(s)`}
                                                    </Button>
                                                </Stack> : 
                                                
                                                (data[`${category}_aws`] && data[`${category}_aws`].length) ?
                                                    <React.Fragment>
                                                        <Typography component="h2" variant="body1" color="primary" gutterBottom sx={{
                                                            color: 'black',
                                                            marginLeft: "2%"
                                                        }}>
                                                            {category} Pictures
                                                        </Typography>
                                                        <ImageList cols={3}>
                                                            {data[`${category}_aws`].map((item, key) => (
                                                                <ImageListItem key={item}>
                                                                    <img
                                                                        src={`${item}`}
                                                                        alt={`${category} Pictures`}
                                                                        loading="lazy"
                                                                        style={{ objectFit: "contain" }}
                                                                    />
                                                                </ImageListItem>
                                                            ))}
                                                        </ImageList>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment></React.Fragment>
                                        }
                                        </Grid>
                                        <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />
                                    </>

                                ))
                            }
                       </>: <></> 
                    }
                    
                    {
                        data.new_categories.map((value, idx) => (
                            <Grid key={idx} item xs={6}>
                                <Stack
                                    spacing={2}
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    {
                                    data[value] ?
                                    <TextField
                                        multiline
                                        placeholder={
                                        data[value] && data[value].length
                                            ? ` Selected: ${
                                                data[value].length
                                            }\n ${data[value].map((value) => value.name)}`
                                            : ""
                                        }
                                        label={`Selected ${value} Picture(s)`}
                                        style={{
                                        width: "50%",
                                        alignSelf: "center",
                                        }}
                                        color="secondary"
                                        variant="filled"
                                        focused
                                        name={value}
                                        InputProps={{
                                        readOnly: true,
                                        }}
                                    />
                                    :<TextField
                                        multiline
                                        id={`${idx}-new_categories`}
                                        label={"Add Image Category"}
                                        value={value}
                                        style={{
                                        width: "50%",
                                        alignSelf: "center",
                                        }}
                                        onChange={(e) => {
                                        let new_categories = [...data.new_categories];
                                        if (e.target.value) {
                                            new_categories[idx] = toPascalCase(e.target.value)
                                            setData((prev) => ({
                                                ...prev,
                                                new_categories,
                                            }));
                                        } else {
                                            new_categories[idx] = e.target.value
                                            setData((prev) => ({
                                                ...prev,
                                                new_categories,
                                            }));
                                        }
                                        }}
                                    />
                                    }
                                    {
                                    value ?
                                    <Stack
                                        spacing={1}
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Button
                                        variant="outlined"
                                        component="label"
                                        startIcon={<PhotoCameraIcon />}
                                        style={{
                                        width: "100%",
                                        alignSelf: "center",
                                        }}
                                    >
                                        {`Select ${toPascalCase(value)} Pic(s)`}
                                        <input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        name={toPascalCase(value).replaceAll(" ", "_")}
                                        onChange={uploadData}
                                        hidden
                                        />
                                    </Button> 
                                    {
                                        idx === data.new_categories.length-1 ?
                                        <AddCircleIcon
                                        color="primary"
                                        sx={{
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            let new_categories = [...data.new_categories];
                                            new_categories.push("");
                                            setData((prev) => ({
                                            ...prev,
                                            new_categories,
                                            }));
                                        }}
                                        /> : <></>
                                    }
                                    
                                    </Stack> : <></>
                                    }
                                </Stack>
                            </Grid>
                        ))
                    }
                     <Divider variant="middle" sx={{ width: '90%', margin: '2%' }} />

                    <Grid item xs={3}>
                        <Button sx={{ width: "100%" }} variant="outlined" color="primary" onClick={handleSubmit}>Submit</Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button sx={{ width: "100%" }} variant="outlined" color="warning" onClick={props.handleDialogClose}>Cancel</Button>
                    </Grid>
                </Grid>}
        </React.Fragment >
    )
}