import axios from 'axios';
import Papa from 'papaparse';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Title from '../Dashboard/Title';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from "react-router-dom";
import TableCell from '@mui/material/TableCell';
import CONSTANTS from '../../helpers/constants';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CircularProgress from '@mui/material/CircularProgress';

export default function Calibration(props) {
    const [selectedFile, setSelectedFile] = React.useState()
    const [parsedData, setParsedData] = React.useState([])
    const [noData, setNoData] = React.useState(true)
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate();

    function uploadData(event) {
        event.preventDefault();
        if (event.target.files) {
            setSelectedFile(event.target.files[0])
            setNoData(false)
            Papa.parse(event.target.files[0], {
                header: true,
                complete: function (results) {
                    if (props.title === 'Sensor Testing') {
                        let data = parseSensorTestingData(results.data)
                        setParsedData(data)
                    } else if (props.title === 'Sensor Calibration') {
                        // sensor_id should exists
                        let filtered_Results = results.data.filter(value => value.sensor_id)
                        // there should not be any duplicates (same id and calibration date)
                        const ids = filtered_Results.map(o => `${o.sensor_id}-${o.calibration_date}`)
                        const unique_results = filtered_Results.filter((value, index) => !ids.includes(`${value.sensor_id}-${value.calibration_date}`, index + 1))
                        setParsedData(unique_results)
                    } else {
                        let data = parseEquipmentTestingData(results.data.filter(value => value.Type))
                        setParsedData(data)
                    }
                }
            });
        }
    }
    async function handleFileSubmit() {
        setLoading(true)
        const headers = {
            'authorization': 'Bearer ' + props.token,
        }
        if (props.title === 'Sensor Testing') {
            // Sensor testing QA data
            await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_WATER_INSERT_API_URL}`, parsedData, { headers })
                .then(() => {
                    setNoData(true)
                    setParsedData([])
                    setSelectedFile()
                    setLoading(false)
                    navigate(CONSTANTS.PAGE_SENSOR_BOARDS_VIEW)
                })
        } else if (props.title === 'Sensor Calibration') {
            // Calibration 
            await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_CAL_INSERT_API_URL}`, parsedData, { headers })
                .then(() => {
                    setNoData(true)
                    setParsedData([])
                    setSelectedFile()
                    setLoading(false)
                    navigate(CONSTANTS.PAGE_SENSOR_BOARDS_VIEW)
                })
            // bundling work.
            // for (i = 0; i < parsedData.length; i++) {
                
            // }
        } else if (props.title === 'Equipment Testing') {
            // Equipment Testing
            await axios.post(`${CONSTANTS.DEFAULT_API_URL}${CONSTANTS.QA_EQUIPMENT_BULK_INSERT_API_URL}`, parsedData, { headers })
                .then(() => {
                    setNoData(true)
                    setParsedData([])
                    setSelectedFile()
                    setLoading(false)
                    navigate(CONSTANTS.PAGE_EQUIPMENT_VIEW)
                })
        }
    }
    function parseSensorTestingData(data) {
        let coupling_counts = 0
        let fixed_data = data.map(value => {
            let new_data = {}
            new_data.sensor_id = value["Sensor"]
            new_data.sensor_housing_id = value["Sensor Housing Unit"] ? value["Sensor Housing Unit"].toUpperCase() : ''
            if (value["Coupling Serial Number 1"]) {
                new_data.coupling_serial_1 = value["Coupling Serial Number 1"].toUpperCase()
                coupling_counts += 1
            }
            if (value["Coupling Serial Number 2"]) {
                new_data.coupling_serial_2 = value["Coupling Serial Number 2"].toUpperCase()
                coupling_counts += 1
            }
            new_data.tested_pressure = value["Pressure Test (psi)"]
            new_data.leak_test = value["Leak Test (Pass/Fail)"] ? value["Leak Test (Pass/Fail)"].toUpperCase() : ''
            new_data.tested_by = value["Tested By"] ? value["Tested By"].toUpperCase() : ''
            new_data.notes = value["Notes"]
            new_data.operational = true
            new_data.water_test_date = value["Date Tested"] ?
                new Date(value["Date Tested"]) : new Date()
            if (coupling_counts > 0) {
                new_data.build_type = CONSTANTS.SENSOR_OLD
            } else {
                new_data.build_type = CONSTANTS.SENSOR_NEW
            }
            coupling_counts = 0
            return new_data
        })
        return fixed_data.filter(data => data.sensor_id)
    }

    function parseEquipmentTestingData(data) {
        let fixed_data = data.map(value => {
            let new_data = {}
            new_data.type = value.Type.replace(/\s/g, '').toUpperCase()
            new_data.test = value['Test'].replace(/\s/g, '').toUpperCase()
            new_data.tester = value['Tester'].replace(/\s/g, '').toUpperCase()
            new_data.notes = value['Notes']
            if (new_data.type === 'BATTERYPACK') {
                new_data.model = "BP"
            } else if (new_data.type === 'TCMPACK') {
                new_data.model = "TP"
            } else if (new_data.type === 'SOLAR30W') {
                new_data.model = "S30W"
            } else if (new_data.type === 'SOLAR50W') {
                new_data.model = "S50W"
            } else if (new_data.type === 'GATEWAY') {
                new_data.model = "G"
            }

            if (new_data.test === 'WATER') {
                new_data.water_test_date = new Date(value["Date Tested"])
            } else {
                new_data.electronic_test_date = new Date(value["Date Tested"])
            }
            new_data.equipment_id = value.equipment_id
            new_data.status = value.Status.toUpperCase()
            return new_data
        })
        return fixed_data
    }
    return (
        <React.Fragment>
            <Title>{props.title}</Title>
            {loading &&
                <Box m={5} p={5}>
                    <CircularProgress />
                </Box>
            }
            {!loading &&
                <Grid container spacing={2}>
                    <Grid item xs={11}>
                        <TextareaAutosize
                            aria-label="empty textarea"
                            placeholder={selectedFile ? ` File Name: ${selectedFile.name}\n Last Modified: ${selectedFile.lastModifiedDate}` : " No File Selected"}
                            style={{
                                width: '100%',
                                height: "100%"
                            }}
                            label={selectedFile ? selectedFile.name : "No File Selected"}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" component="label">
                            Upload
                            <input hidden accept="text/csv" type="file" onChange={uploadData} />
                        </Button>
                    </Grid>
                    <Grid item xs={11}>
                        {(!noData && props.title === 'Sensor Calibration') ? <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Sensor Id</TableCell>
                                        <TableCell align="right">Caliberation Date</TableCell>
                                    </TableRow>

                                </TableHead>
                                <TableBody>
                                    {parsedData.map((row, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.sensor_id}
                                            </TableCell>
                                            <TableCell align="right">{row.calibration_date}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : <React.Fragment></React.Fragment>}
                        {(!noData && props.title === 'Sensor Testing') ? <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Sensor Housing Unit</TableCell>
                                        {/* <TableCell align="right">Coupling Serial Number 1</TableCell> */}
                                        {/* <TableCell align="right">Coupling Serial Number 2</TableCell> */}
                                        <TableCell align="right">Sensor Id</TableCell>
                                        <TableCell align="right">Pressure Test (PSI)</TableCell>
                                        <TableCell align="right">Leak Test (Pass/Fail)</TableCell>
                                        <TableCell align="right">Date Tested</TableCell>
                                        <TableCell align="right">Tested By</TableCell>
                                        <TableCell align="right">Notes</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {parsedData.map((row, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">{row.sensor_housing_id ? row.sensor_housing_id : '-'}</TableCell>
                                            {/* <TableCell align="right"> {row.coupling_serial_1 ? row.coupling_serial_1 : '-'} </TableCell> */}
                                            {/* <TableCell align="right">{row.coupling_serial_2 ? row.coupling_serial_2 : '-'}</TableCell> */}
                                            <TableCell align="right">{row.sensor_id ? row.sensor_id : '-'}</TableCell>
                                            <TableCell align="right">{row.tested_pressure ? row.tested_pressure : '-'}</TableCell>
                                            <TableCell align="right">{row.leak_test ? row.leak_test : '-'}</TableCell>
                                            <TableCell align="right">{row.water_test_date.toDateString("MM-DD-YYYY")}</TableCell>
                                            <TableCell align="right">{row.tested_by ? row.tested_by : '-'}</TableCell>
                                            <TableCell align="right">{row.notes ? row.notes : '-'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : <React.Fragment></React.Fragment>}

                        {(!noData && props.title === 'Equipment Testing') ? <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Equipment Type</TableCell>
                                        <TableCell align="right">Equipment ID</TableCell>
                                        <TableCell align="right">Test</TableCell>
                                        <TableCell align="right">Tester</TableCell>
                                        <TableCell align="right">Date Tested</TableCell>
                                        <TableCell align="right">Notes</TableCell>
                                        <TableCell align="right">Pass/Fail</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {parsedData.map((row, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">{row.type ? row.type : '-'}</TableCell>
                                            <TableCell align="right">{row.equipment_id ? row.equipment_id : '-'}</TableCell>
                                            <TableCell align="right">{row.test ? row.test : '-'}</TableCell>
                                            <TableCell align="right">{row.tester ? row.tester : '-'}</TableCell>
                                            <TableCell align="right">{row.water_test_date ? row.water_test_date.toDateString("MM-DD-YYYY") :
                                                row.electronic_test_date.toDateString("MM-DD-YYYY")}</TableCell>
                                            <TableCell align="right">{row.notes ? row.notes : '-'}</TableCell>
                                            <TableCell align="right">{row.status ? row.status : '-'}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> : <React.Fragment></React.Fragment>}

                    </Grid>
                    <Grid item xs={1}>
                        <Button variant="contained" component="label" onClick={handleFileSubmit} disabled={noData}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>}
        </React.Fragment>
    )
}